@import '../../styles/uitilities.scss';

.slideshow_list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    &_item {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 0 15px;
        margin-bottom: 30px;
        @include max(1599.98) {
            flex: 0 0 25%;
            max-width: 25%;
        }
        @include max(991.98) {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
        }
        @include max(575.98) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include max(424.98) {
            flex: 0 0 100%;
            max-width: 100%;
        }
        &:hover {
            cursor: pointer;
        }
        .shimmer-styles {
            width: 100%;
            background: $shimmer-bg-alt;
            background-image: $shimmer-gradient-alt;
            background-repeat: no-repeat;
            background-size: 800px 100%;
            border-radius: 5px;
            
            -webkit-animation-duration: 1s;
            -webkit-animation-fill-mode: forwards; 
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-name: placeholderShimmer;
            -webkit-animation-timing-function: linear;
        }
        figure {
            img {
                opacity: 0;
                transform: scale(1.1);
                transform-origin: center;
                will-change: transform;
                // transition: 0.3s ease-in-out opacity, 0.3s ease-in-out transform;
            }
        }
        svg {
            opacity: 1;
            visibility: visible;
            transition: 0.3s ease-in-out opacity;
            transition-delay: 0.4s;
        }
        &:global(.slideshow-loading) {
            figure {
                @extend .shimmer-styles;
            }
            svg {
                opacity: 0;
                visibility: hidden;
            }
            h4 {
                width: 70%;
                height: 18px;
                @extend .shimmer-styles;
                margin-bottom: 10px;
            }
            span {
                display: block;
                width: 40%;
                height: 15px;
                @extend .shimmer-styles;
            }
        }
        &:global(.slideshow-loaded) {
            figure {
                img {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
        &_image {
            position: relative;
            overflow: hidden;
            max-width: 288px;
            border-radius: 12px;
            margin-bottom: 18px;
            @include max(424.98) {
                max-width: 100%;
            }
            img { 
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
                max-width: 100%;
                max-height: 100%; 
            }
            &::after {
                content: "";
                display: block;
                height: 0;
                width: 100%;
                padding-bottom: calc(203 / 288 * 100%);
            }
        }
        .slideshow {
            &_name {
                font-size: size(22);
                font-weight: $font-bold;
                color: $text-color-alt;
                margin-bottom: 5px;
                line-height: 28px;
            }
            &_duration {
                font-size: size(16);
                font-weight: $font-regular;
                color: $text-color-alt;
            }
            &_play_btn {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }


}

.slideshow_list_item{
    position: relative;
    .slideshow_list_item_image {
        img {
            transition: 0.3s ease-out transform;
        }
    }
    &:hover {
        .slideshow_list_item_image {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.preSelectAlbum{
    // opacity: 0.6;
    .selectionItem {
        box-shadow: 0 0 100vmin 100vmin #00000062;
    }
}

.selectionItem {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    z-index: 10;
    bottom: 15px;
    right: 15px;
    border: 2px solid #bfb5b5;
    // box-shadow: 0 0 100vmin 100vmin #ffffff50;
}

.gallery_select_tick {
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 5;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #1d5eff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

@keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    
    100% {
      background-position: 468px 0; 
    }
}