@import "../../../../styles/uitilities.scss";

.user_profile_family_member{
    width: 130px;
    max-width: 50%;
    padding: 10px;
    &_figure{
        position: relative;
        width: 100%;
        overflow: hidden;
        border-radius: 6px;
        margin-bottom: 8px;
        &:before{
            content: "";
            width: 100%;
            left: 0;
            padding-bottom: 111%;
            display: block;
        }
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    &_name{
        font-size: size(16);
        font-weight: 700;
        color: $text-color;
        margin-bottom: 1px;
    }
    &_relation{
        font-size: size(14);
        color: #767678;
    }
}
