@import "../../styles/uitilities.scss";

.dashboard {
  display: flex;
  min-height: calc(100vh - 83px);
  
  background: $dashboard-bg;
  transition: padding 0.3s ease-in-out;
  will-change: padding;
  @include max(1279.98) {
    min-height: calc(100vh - 52px);
  }
  &_sidebar {
    flex: 0 0 307px;
    max-width: 307px;
    padding: 61px 36px 0;
    background: $dashboard-bg-alt;
    position: relative;
    z-index: 2;
    transition: 0.5s ease max-width, 0.3s ease padding;
    @include max(1439.98) {
      flex: 0 0 250px;
      max-width: 250px;
    }
    @include max(1199.98) {
      display: none;
    }
    &_wrap {
      position: sticky;
      top: 132px;
    }
    :global(.advertisement-tab-swiper) {
      width: 235px;
      @include max(1439.98) {
        width: 178px;
      }
    }
    &.sidebar_collapse {
      flex: 0 0 100px;
      max-width: 100px;
      padding: 61px 30px 17px 20px;
      .collapse_holder {
        right: -30px;
        &_btn {
          svg {
            transform: rotate(180deg);
          }
        }
      }
      .dashboard_sidebar {
        &_title {
          opacity: 0;
        }
        &_links {
          li {
            span {
              opacity: 0;
            }
          }
        }
        &_menu {
          position: initial;
          overflow: hidden;
        }
      }
    }
    .collapse_holder {
      position: absolute;
      top: 10px;
      right: -30px;
      &_image {
        path {
          fill: $dashboard-bg-alt;
        }
      }
      &_btn {
        border: none;
        background: none;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        transform-origin: center;
        svg {
          transition: 0.5s ease transform;
        }
      }
    }
    &_title {
      font-size: size(14);
      font-weight: $font-medium;
      color: $text-color;
      margin-bottom: 15px;
      white-space: nowrap;
      transition: 0.3s ease all;
    }
    &_links {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 35px;
      position: relative;
      z-index: 9;
      li {
        border-radius: 6px;
        a {
          text-decoration: none;
          color: $dashboard-menu-item;
          transition: 0.3s ease all;
          white-space: nowrap;
          display: block;
          @include min(1200) {
            padding: 14px 16px;
          }
          @include max(1199.98) {
            padding: 10px 16px;
          }
          svg {
            margin-right: 25px;
          }
          &:hover {
            color: $dashboard-menu-active;
            svg {
              path {
                fill: $dashboard-menu-active;
              }
            }
          }
        }
      }
    }
    &_collapse{
      @include min(1200){
        padding-left: 100px;
      }
    }
    &_expand{
      @include min(1440){
        padding-left: 307px;
      }
      @include min-max(1280,1439.98) {
        padding-left: 250px;
      }
    }
  }
  &_tab_active {
    a {
      color: $dashboard-menu-active !important;
      svg {
        path {
          fill: $dashboard-menu-active;
        }
      }
    }
    @include min(1200) {
      background-color: $dashboard-menu-active-bg;
    }
  }
  &_outlet {
    flex: 1;
    z-index: 1;
    // @include max(1199.98) {
    //   overflow-x: auto;
    // }
    @include max(991.98) {
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .ad_sec {
    transition: 0.3s ease all;
  }
  :global(.amily-users-ad),
  :global(.advertisement-tab-swiper) {
    animation: fadeInAd 0.5s ease-in-out 0s forwards;
  }


  
  .dashboard_sidebar {
    position: fixed;
    left: 0;
    top: 83px;
    height: calc(100vh - 83px);
    padding-top: 0;
    > div {
      opacity: 0;
      animation: fadeInAd 0.5s ease-in-out 0.3s forwards;
    }
    &.sidebar_collapse{
      :global(.amily-users-ad),
      :global(.advertisement-tab-swiper) {
        animation: fadeOutAd 0.2s ease-in-out 0s forwards;
        pointer-events: none;
      }
      .dashboard_sidebar_links{
        li{
          overflow: hidden;
        }
      }
    }
  }

  @include max(1199.98) {
    padding-bottom: 43px;
  }
}

.onlineStatus {
  position: absolute;
  top: 96%;
  left: 80%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.dashboard_sidebar_mob {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: $dashboard-bg-alt;
  z-index: 9999;
  box-shadow: 0 0 0.5px #707070;
  .dashboard_sidebar_links{
    display: flex;
    justify-content: space-around;
    margin-bottom: 0;
    @include max(1280) {
      margin-left: auto;
      margin-right: auto;
      max-width: 600px;
    }
    li {
      a {
        svg {
          margin: auto;
        }
      }
    }
  }
}

@keyframes fadeInAd {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOutAd {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.dashboard_sidebar_menu{
  max-height: 100%;
  overflow: auto;
  width: 100%;
  padding-bottom: 30px;
  scrollbar-width: none;
  position: sticky;
  top: 0;
  padding-top: 61px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.collapse_menu_closed{
  background-color: #23CC2B!important;
  width: 42px;
  height: 42px;
  border-radius: 42px;
  
}
.collapse_menu_closed_icon{
  path{
    fill: white;
  }
}

:global(.language-open) {
  z-index: 99;
}