@import "../../../styles/uitilities.scss";
.album_full_view{
    max-width: 100vw;
    height: 100vh;
    width: 100%;
    position: fixed;
    background-color: rgba(#212124,95%);
    top: 0;
    left: 0;
    z-index: 10000;
    @include min(992){
        padding: 75px 75px 125px 75px;
    }
    @include max(991.98){
        padding: 52px 0px 90px 0px;
    }
    .full_view_slider{
        height: 100%;
        :global(.swiper-button-next),
        :global(.swiper-button-prev){
            height: 57px;
            width: 57px;
            background-color: rgba(255,255,255,12%);
            border-radius: 80px;
            transition: background-color 0.3s ease-in;
            &:after{
                font-size: 14px;
            }
            &:hover{
                background-color: rgba(255,255,255,20%);
            }
        }
        :global(.swiper-slide){
            display: flex;
            overflow: hidden;
            transition: 0.53s ease opacity;
            .full_view_slider_img_wrap{
                margin: auto;
                height: 100%;
            }
        }
        :global(.swiper-slide:not(.swiper-slide-active)){
            opacity: 0 !important;
        }
        :global(.swiper-slide-active) {
            opacity: 1 !important;
        }
        @include max(991.98){
            :global(.swiper-button-next),
            :global(.swiper-button-prev){
                display: none;
            }
        }
    }
    .full_view_slider_thumb_wrap{
        max-width: 944px;
        overflow: hidden;
        width: 100vw;
        margin: 12px auto;
        @include max(1199.98) {
            margin-top: -30px;
        }
    }
    .full_view_slider_thumb{
        max-width: 80px;
        height: auto;
        overflow: visible;


        @include max(991.98){
            transform: scale(0.9);
        }

        :global(.swiper-slide){
            border-radius: 80px;
            cursor: pointer;
            user-select: none;
            position: relative;
            border: 2px solid transparent;
            figure{
                pointer-events: none;
                position: relative;
                &:before{
                    content: "";
                    width: 100%;
                    padding-bottom: 100%;
                    display: block;
                }
            }
            
            &:global(.slide_num_prev_0) {
                padding: 0px 0;
                img{
                    box-shadow: 0 0 0 2px #23CC2B;
                }
                
            }
            &:global(.slide_num_prev_1){
                border-color: transparent!important;
            }
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 80px;
            position: absolute;
            left: 0px;
            top: 0px;
        }
        :global(.swiper-slide){
            overflow: visible;
            padding: 0 0;
            margin: auto;
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
        }
        :global(.slide_num_next_1){
            transform: scale(.93) translateX(10px);
        }
        :global(.slide_num_prev_1){
            transform: scale(.93) translateX(-10px);
        }

        :global(.slide_num_next_2){
            transform: scale(.85) translateX(15px);
        }
        :global(.slide_num_prev_2){
            transform: scale(.85) translateX(-15px);
        }


        :global(.slide_num_next_3){
            transform: scale(.8) translateX(15px);
        }
        :global(.slide_num_prev_3){
            transform: scale(.8) translateX(-15px);
        }
        :global(.slide_num_next_4){
            transform: scale(.75) translateX(10px);
        }
        :global(.slide_num_prev_4){
            transform: scale(.75) translateX(-10px);
        }
        :global(.slide_num_next_5){
            transform: scale(.7) translateX(0px);
        }
        :global(.slide_num_prev_5){
            transform: scale(.7) translateX(-0px);
        }
        :global(.slide_num_next_7),
        :global(.slide_num_next_6){
            transform: scale(.65) translateX(-10px);
        }
        :global(.slide_num_prev_7),
        :global(.slide_num_prev_6){
            transform: scale(.65) translateX(10px);
            
            
        }
        :global(.slide_num_next_7),
        :global(.slide_num_prev_7){
            opacity: 0;
        }
        :global(.slide_num_prev_7),
        :global(.slide_num_prev_6),
        :global(.slide_num_prev_5){
            figure{
                position: relative;
                border-radius: 80px;
                overflow: hidden;
                &:after{
                    content: "";
                    background: rgb(0,0,0);
                    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: -2;
                }
            }
        }
        :global(.slide_num_next_7),
        :global(.slide_num_next_6),
        :global(.slide_num_next_5){
            figure{
                position: relative;
                border-radius: 80px;
                overflow: hidden;
                &:after{
                    content: "";
                    background: rgb(0,0,0);
                    background: linear-gradient(-90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: -2;
                }
            }
        }
    }
    @include min(1500){
        :global(.swiper-button-prev){
            left: 125px;
        }
        :global(.swiper-button-next){
            right: 125px;
        }
    }
}

.album_full_view_header{
    position: absolute;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    left: 0;
    @include min(992){
        padding: 0 50px;
    }
    @include max(1599.98) {
        top: 25px;
    }
    @include max(991.98){
        padding: 16px;
        top: 0;
    }
    &_btn{
        color: white;
    }
    &_rightnav,
    &_leftnav{
        width: 100%;
        @include min(992){
            max-width: 50%;
            flex: 0 0 50%;
        }
        svg{
            path{
                fill: currentColor;
            }
        }
    }
    &_leftnav{
        .album_full_view_header_btn{
            background-color: transparent;
            border-color: transparent;
            font-weight: 400;
            font-size: size(17);
            text-decoration: none;
            &:hover{
                color: $primary-color;
            }
            @include min(992){
                padding: 3px 0;
            }
            &:focus{
                color: $primary-color;
            }
            svg{
                transform: translateY(-1px);
                margin-right: 12px;
            }
            
        }
        @include max(991.98){
            max-width: 40px;
            flex: 0 0 40px;
        }
    }
    &_rightnav{
        text-align: right;
        @include max(991.98){
            padding-top: 2px;
        }
        :global(.dropdown){
            display: inline-block;
        }
        .album_full_view_header_btn{
            background-color: transparent;
            font-size: size(17);
            font-weight: 400;
            text-decoration: none;
            padding: 5px 15px;
            width: auto;
            min-width: 52px;
            border-color: currentColor;
            margin: 0 7px;
            a {
                color: $white;
            }
            &:hover {
                a {
                    color: $primary-color;
                }
            }
        }
        :global(.dropdown-toggle){
            min-width: 30px;
            padding: 0px;
            text-align: center;
            background-color: transparent;
            border: none;
            border-radius: 50px;
            margin: 0 7px;
            width: 30px;
            height: 30px;
            &:after{
                display: none;
            }
        }
        @include max(991.98){
            max-width: calc(100% - 40px);
            flex: 0 0 calc(100% - 40px);
        }
    }
    :global(.dropdown-menu) {
      background-color: $white;
      border-radius: 10px;
      padding: 15px 13px;
      position: relative;
      &::before {
        content: "";
        background: $white;
        position: absolute;
        right: 20px;
        width: 14px;
        height: 14px;
      }
      &[x-placement="bottom-start"] {
        &::before {
          top: 0;
          transform: translateY(-50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 0 0 5px;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
          transition: 0.5s ease all;
        }
      }
      &[x-placement="bottom-end"] {
        transform: translate(5px, 45px) !important;
        &::before {
          top: 0;
          transform: translateY(-50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 0 0 5px;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
          transition: 0.5s ease all;
        }
      }
      &[x-placement="top-start"] {
        &::before {
          bottom: 0;
          transform: translateY(50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 5px 0 0;
          border-bottom: 1px solid #fff;
          border-left: 1px solid #fff;
          transition: 0.5s ease all;
        }
      }
      a {
        font-size: size(17);
        font-weight: $font-regular;
        padding: 6px 10px;
        border-radius: 6px;
        overflow: hidden;
        &:hover,
        &:focus {
          color: $text-alt;
          background-color: $dropdown-bg-hover;
        }
      }
    }
    :global(.btn){
        &:focus{
            color: $primary-color;
        }
    }
    &_close{
        color: white;
        padding: 0px;
        min-width: 32px;
        background-color: transparent;
        border: none;
        margin-left: 18px;
    }
    :global(.btn-primary.dropdown-toggle:hover),
    :global(.show > .btn-primary.dropdown-toggle){
        background-color: rgba(255,255,255,0.12)!important;
        color: white!important;
    }
    
}


.album_full_view_header_btn_mob{
    background-color: transparent;
    border: none;
    color: white;
    margin: 0 9px;
    svg{
        color: white;
        path{
            fill: currentColor;
        }
    }

    &.tray_actions_btn_liked{
        svg{
            path{
                &:nth-child(1){
                    fill: rgb(255, 39, 39);
                }
            }
        }
    }
    &.tray_actions_btn_no_like{
        svg{
            path{
                &:nth-child(1){
                    fill: none;
                }
                &:nth-child(2){
                    fill: white;
                }
            }
        }
    }
}


