// deafult Color
:root {
  --body-bg: #ffffff;
  --header-bg: #ffffff;
  --text-color: #252533;
  --text-color-alt: #000000;
  --input-bg: #ffffff;
  --dark-bg-alt: #001631;
  --dark-bg-alt-2: #f6f6f6;
  --social-icon-color: #2b2b2b;
  --social-icon-hover: #ffffff;
  --social-icon-bg: #2b2b2b;
  --footer-bg: #f5f5f7;
  --post-footer-color: #777778;
  --post-footer-bg: #efefef;
  --card-bg: #ffffff;
  --text-alt-hover: #0a2cea;
  --dark-bg-alt-3: #f3f6fb;
  --modal-link: #085cfd;
  --modal-bg: #ffffff;
  --tab-bg: #f2f2f2;
  --tab-active-bg: #ffffff;
  --label-bg: #ffece2;
  --label-color: #f03b0f;
  --profile-details-color: #193b68;

  --gradient-bg: linear-gradient(90deg, #001832, #03393a);

  --card-box-shadow: #d7dae4;
  --card-box-shadow-alt: #cfdef746;

  --card-border: #e3e5f169;
  --input-border: #d3d5db;
  --input-border-alt: #d3d5db;
  --dashboard-item-border: #e0e2e8;

  --pagination-bullet: #c2c5d4;
  --pagination-bullet-active: #062cea;

  --dashboard-bg: #f6f6f6;
  --dashboard-bg-alt: #ffffff;

  --dashboard-header-bg: #ffffff;

  --dashboard-menu-item: #a09ea6;
  --dashboard-menu-active: #1d5eff;
  --dashboard-menu-active-bg: #f7f8ff;

  --dashboard-btn-hover: #ffffff;
  --dashboard-btn-gray: #a09ea6;
  --dashboard-btn-gray-hover: #000000;
  --dashboard-btn-gray2: #d9d9d9;
  --dashboard-btn-gray2-text: #707070;
  --dashboard-btn-gray2-hover: #bebebe;

  --dashboard-add-btn: #1d5eff;
  --dashboard-add-btn-border: #a2a4ac;
  --dashboard-add-btn-hover: #ffffff;
  --dashboard-add-btn-bg-hover: #1d5eff;

  --rearrange-close-bg: #00000012;
  --plan-month: #00000079;
  --menu-link: #c9c9c9;
  --mobile-menu-bg: #ffffff;
  --close-menu-bg: #00163116;
  --swiper-nav-bg: #000000;
  --banner-video-unload-bg: #e9e9e9;
  --file-upload-bg: #fbfffb;
  --file-upload-hover-bg: #eaf8ea;
  --file-preview-bg: #ffffff;
  --dark-toggle: #ffffff;
  --dark-toggle-bg: #000000;
  --dashboard-loader: #ffffff;

  --gallery-img-bg: #dbdbdb;

  --shimmer-bg: #f6f7f8;
  --shimmer-gradient: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );

  --header-menu-item: #252533;
  --header-menu-item-active: #0a2cea;
  --testimonial-swiper-arrow-bg: #ffffff;
  --otp-border: #e3e5f1;
  --shimmer-bg-alt: #e6e6e6;
  --shimmer-gradient-alt: linear-gradient(
    to right,
    #e6e6e6 5%,
    #e1e1e1 25%,
    #e6e6e6 35%
  );
  --shimmer-green-bg: #00968850;
  --shimmer-green-gradient: linear-gradient(
    to right,
    #00968830 0%,
    #00968860 30%,
    #00968830 40%,
    #00968830 100%
  );
  --contact-input: #595959;
  --contact-page-bg: #f3f6fb;
  --text-opacity: #00000049;
  --input-label: #8e9097;
  --placeholder-image: #d3d5db;
  --text-label-grey: #767678;
  --dashboard-header-border: #D3D5DB;
  --button-dark-alt: #ffffff;
  --button-dark-border-alt: #000000;
  --slider-track: #dee2e6;
  --border-opacity-dark: #d3d5db8a;
  --blank-folder-outer: #E0E2E8;
  --blank-folder-bg: #C0C1C7;
}
.dark {
  --body-bg: #070724;
  --header-bg: #0c0c2b;
  --text-color: #ffffff;
  --text-color-alt: #ffffff;
  --input-bg: #3d3d4a;
  --dark-bg-alt: #12122e;
  --dark-bg-alt-2: #292940;
  --social-icon-color: #ffffff;
  --social-icon-hover: #2b2b2b;
  --social-icon-bg: #ffffff;
  --footer-bg: #050519;
  --post-footer-color: #ffffff65;
  --post-footer-bg: #0b0b1e;
  --card-bg: #323241;
  --text-alt-hover: #267ffd;
  --dark-bg-alt-3: #12122e;
  --modal-link: #23cc2b;
  --modal-bg: #323241;
  --tab-bg: #22223b;
  --tab-active-bg: #555565;
  --label-bg: #5b5b68;
  --label-color: #ffffff;
  --profile-details-color: #ffffff;

  --gradient-bg: #12122e;

  --card-box-shadow: #1a1a1e28;
  --card-box-shadow-alt: #1a1a1e28;

  --card-border: transparent;
  --input-border: #3d3d4a;
  --input-border-alt: #ffffff;
  --dashboard-item-border: #e0e2e8;

  --pagination-bullet: #ffffffb3;
  --pagination-bullet-active: #ffffff;

  --dashboard-bg: #1d1d29;
  --dashboard-bg-alt: #292936;
  --dashboard-loader: #323241;
  --dashboard-header-bg: #323241;

  --dashboard-menu-item: #bfbfc3;
  --dashboard-menu-active: #ffffff;
  --dashboard-menu-active-bg: #1d1d29;

  --dashboard-btn-hover: #000000;

  --dashboard-btn-gray: #ffffff;
  --dashboard-btn-gray-hover: #bfbfc3;
  --dashboard-btn-gray2: #ffffff;
  --dashboard-btn-gray2-text: #000000;
  --dashboard-btn-gray2-hover: #d9d9d9;

  --dashboard-add-btn: #ffffff;
  --dashboard-add-btn-border: #ffffff;
  --dashboard-add-btn-hover: #000000;
  --dashboard-add-btn-bg-hover: #ffffff;

  --rearrange-close-bg: #ffffff;
  --plan-month: #ffffff;
  --menu-link: #ffffff;
  --mobile-menu-bg: #1d1d29;
  --close-menu-bg: #ffffff16;
  --swiper-nav-bg: #23cc2b;
  --banner-video-unload-bg: #292940;
  --file-upload-bg: #1c2c27;
  --file-upload-hover-bg: #314c44;
  --file-preview-bg: #1d1d29;
  --dark-toggle: #000000;
  --dark-toggle-bg: #ffffff;

  --gallery-img-bg: #323241;

  --shimmer-bg: #3a3a3a;
  --shimmer-gradient: linear-gradient(
    to right,
    #3a3a3a 0%,
    #3f3f3f 10%,
    #4a4a4a 20%,
    #3f3f3f 30%,
    #3a3a3a 50%,
    #3a3a3a 100%
  );

  --header-menu-item: #ffffff70;
  --header-menu-item-active: #ffffff;
  --testimonial-swiper-arrow-bg: #383848;
  --otp-border: transparent;
  --shimmer-bg-alt: #323241;
  --shimmer-gradient-alt: linear-gradient(
    to right,
    #323241 0%,
    #343444 10%,
    #373748 20%,
    #3c3c4e 30%,
    #323241 50%,
    #323241 100%
  );
  --shimmer-green-bg: #00968850;
  --shimmer-green-gradient: linear-gradient(
    to right,
    #00968830 0%,
    #00968860 30%,
    #00968830 40%,
    #00968830 100%
  );
  --contact-input: #dddde5;
  --contact-page-bg: #070724;
  --text-opacity: #ffffff49;
  --input-label: #ffffff;
  --placeholder-image: #7b7b94;
  --text-label-grey: #ffffff68;
  --dashboard-header-border: #44444E;
  --button-dark-alt: #191e2b;
  --button-dark-border-alt: #191e2b;
  --slider-track: #5a5d68;
  --border-opacity-dark: #d3d5db56;
  --blank-folder-outer: #323241;
  --blank-folder-bg: #ffffff;
}

$body-background: var(--body-bg) !default;
$header-background: var(--header-bg) !default;
$text-color: var(--text-color) !default;
$text-color-alt: var(--text-color-alt) !default;
$input-background: var(--input-bg) !default;
$dark-bg-alt: var(--dark-bg-alt) !default;
$dark-bg-alt-2: var(--dark-bg-alt-2) !default;
$social-icon-color: var(--social-icon-color) !default;
$social-icon-hover: var(--social-icon-hover) !default;
$social-icon-bg: var(--social-icon-bg) !default;
$footer-bg: var(--footer-bg) !default;
$post-footer-color: var(--post-footer-color) !default;
$post-footer-bg: var(--post-footer-bg) !default;
$card-bg: var(--card-bg) !default;
$text-alt-hover: var(--text-alt-hover) !default;
$dark-bg-alt-3: var(--dark-bg-alt-3) !default;
$modal-link: var(--modal-link) !default;
$modal-bg: var(--modal-bg) !default;
$tab-bg: var(--tab-bg) !default;
$tab-active-bg: var(--tab-active-bg) !default;
$label-bg: var(--label-bg) !default;
$label-color: var(--label-color) !default;
$dark-toggle: var(--dark-toggle) !default;
$dark-toggle-bg: var(--dark-toggle-bg) !default;
$profile-details-color: var(--profile-details-color) !default;

$dashboard-loader: var(--dashboard-loader) !default;

$gradient-bg: var(--gradient-bg) !default;

$card-box-shadow: var(--card-box-shadow) !default;
$card-box-shadow-alt: var(--card-box-shadow-alt) !default;

$card-border: var(--card-border) !default;
$input-border: var(--input-border) !default;
$input-border-alt: var(--input-border-alt) !default;
$dashboard-item-border: var(--dashboard-item-border) !default;

$pagination-bullet: var(--pagination-bullet) !default;
$pagination-bullet-active: var(--pagination-bullet-active) !default;

$dashboard-bg: var(--dashboard-bg) !default;
$dashboard-bg-alt: var(--dashboard-bg-alt) !default;

$dashboard-header-bg: var(--dashboard-header-bg) !default;

$dashboard-menu-item: var(--dashboard-menu-item) !default;
$dashboard-menu-active: var(--dashboard-menu-active) !default;
$dashboard-menu-active-bg: var(--dashboard-menu-active-bg) !default;

$dashboard-btn-hover: var(--dashboard-btn-hover) !default;
$dashboard-add-btn: var(--dashboard-add-btn) !default;
$dashboard-add-btn-border: var(--dashboard-add-btn-border) !default;
$dashboard-add-btn-hover: var(--dashboard-add-btn-hover) !default;
$dashboard-add-btn-bg-hover: var(--dashboard-add-btn-bg-hover) !default;

$dashboard-btn-gray: var(--dashboard-btn-gray) !default;
$dashboard-btn-gray-hover: var(--dashboard-btn-gray-hover) !default;
$dashboard-btn-gray2: var(--dashboard-btn-gray2) !default;
$dashboard-btn-gray2-hover: var(--dashboard-btn-gray2-hover) !default;
$dashboard-btn-gray2-text: var(--dashboard-btn-gray2-text) !default;

$rearrange-close-bg: var(--rearrange-close-bg) !default;
$plan-month: var(--plan-month) !default;
$menu-link: var(--menu-link) !default;
$mobile-menu-bg: var(--mobile-menu-bg) !default;
$close-menu-bg: var(--close-menu-bg) !default;
$swiper-nav-bg: var(--swiper-nav-bg) !default;
$banner-video-unload-bg: var(--banner-video-unload-bg) !default;
$file-upload-bg: var(--file-upload-bg) !default;
$file-upload-hover-bg: var(--file-upload-hover-bg) !default;
$file-preview-bg: var(--file-preview-bg) !default;
$text-opacity: var(--text-opacity) !default;
$input-label: var(--input-label) !default;

$gallery-img-bg: var(--gallery-img-bg) !default;
$shimmer-bg: var(--shimmer-bg) !default;
$shimmer-gradient: var(--shimmer-gradient) !default;

$header-menu-item: var(--header-menu-item) !default;
$header-menu-item-active: var(--header-menu-item-active) !default;

$testimonial-swiper-arrow-bg: var(--testimonial-swiper-arrow-bg) !default;
$otp-border: var(--otp-border) !default;

$shimmer-bg-alt: var(--shimmer-bg-alt) !default;
$shimmer-gradient-alt: var(--shimmer-gradient-alt) !default;
$shimmer-green-bg: var(--shimmer-green-bg) !default;
$shimmer-green-gradient: var(--shimmer-green-gradient) !default;
$contact-input: var(--contact-input) !default;
$contact-page-bg: var(--contact-page-bg) !default;
$placeholder-image: var(--placeholder-image) !default;
$text-label-grey: var(--text-label-grey) !default;
$dashboard-header-border: var(--dashboard-header-border) !default;
$button-dark-alt: var(--button-dark-alt) !default;
$button-dark-border-alt: var(--button-dark-border-alt) !default;
$slider-track: var(--slider-track) !default;
$border-opacity-dark: var(--border-opacity-dark) !default;
$blank-folder-outer: var(--blank-folder-outer) !default;
$blank-folder-bg: var(--blank-folder-bg) !default;



$searchbar-border: #dde8f8 !default;

$error-message: #ff6261 !default;

$section-background-light: #f3f6fb;

$primary-color: #267ffd !default;
$secondary-color: #001631 !default;

$white: #fff !default;
$black: #000 !default;
$red: #ff6261 !default;
$blue: #062cea !default;
$blue-alt: #233ec2 !default;
$yellow: #ffce00 !default;
$green: #23cc2b !default;
$green-alt: #098f11 !default;
$dark-alt: #2b2b2b !default;
$light-grey: #efefef !default;
$dark-grey: #606060 !default;
$alt-grey: #707070 !default;
$alt-light: #d8d8d8 !default;
$orange-alt: #d9800b !default;
$orange-red-alt: #f03b0f !default;
$orange-light: #ffece2 !default;

$border-grey: #b0b4b3 !default;
$button-grey: #a09ea6 !default;

$dropdown-bg: #26325d !default;
$dropdown-bg-hover: #e4e7ef !default;

$section-background-grey: #f6f6f6 !default;

$box-shadow-light: #c4cae9;
$box-shadow-light-alt: #cfdef7;
$box-shadow-light-alt-2: #d7dae4;

$btn-primary: $primary-color;
$btn-primary-hover: #014afe;

$btn-secondary: #1d5eff;
$btn-tertiary: $blue;

$pagination-nav: #b4b4b4 !default;

$toggle-grey: #a2a4ac !default;

/* Border Color */
$border-color: #d3d5db !default;
$border-color-2: #b1b1b1;
$border-color-alt: #e3e5f1 !default;

$list-bullet-tick: #23cc2b !default;
$green-alt: #0fa116 !default;

$delete-red: #fc4343 !default;

/* Text Color */
$text-color: var(--text-color) !default;
$text-alt: #252533 !default;
$text-alt-2: #333333 !default;
$text-alt-hover: #0a2cea !default;

$base-font: 1rem !default;

/* Font Family */
$font-family: "Ubuntu", sans-serif;
$font-family-inter: "Inter", sans-serif;
$font-family-lato: "Lato", sans-serif;

//Font weight
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-weight-title: $font-semibold;

@mixin px-rem($pxValue, $property: "font-size", $standard-size: 16) {
  #{$property}: $pxValue/$standard-size + rem;
}
$base-size: 16;
@function size($target, $context: $base-size) {
  @return ($target / $context) * 1rem;
}

//   Media query
@mixin min-max($resMin, $resMax) {
  @media (min-width: $resMin+px) and (max-width: $resMax+px) {
    @content;
  }
}
@mixin max($res) {
  @media (max-width: $res+px) {
    @content;
  }
}
@mixin min($res) {
  @media (min-width: $res+px) {
    @content;
  }
}

@mixin button($btn_color, $url, $x1, $y1, $bor, $col) {
  background: $btn_color;
  -webkit-mask: url($url);
  mask: url($url);
  -webkit-mask-size: $x1 $y1;
  mask-size: $x1 $y1;
  border: $bor;
  color: $col;
}
@-webkit-keyframes btnAnim {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@keyframes btnAnim {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@-webkit-keyframes btnAnim2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

@keyframes btnAnim2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

//  bootstrap variables
$btn-font-size: size(18);
$btn-font-weight: $font-medium;
$btn-line-height: 1;
$btn-padding-x: 32px;
$btn-padding-y: 15px;
$btn-border-radius: 6px;

$btn-font-size-sm: size(18);
$btn-padding-x-sm: 21px;
$btn-padding-y-sm: 13px;
$btn-border-radius-sm: 6px;

$btn-secondary-hover: $btn-primary;
$btn-tertiary-hover: $btn-primary;

$btn-disabled: #b4b4b4;

@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
