@import '../../styles/uitilities.scss';

.home_external_links {
    &_card {
      position: relative;
      border-radius: 13px;
      box-shadow: 0 3px 6px rgba($black, 0.16);
      margin-bottom: 30px;
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            &:hover {
                ~ .home_external_links_card_link {
                    .link_text {
                        display: block;
                    }
                }
            }
        }
        &_image {
            position: relative;
            overflow: hidden;
            margin-bottom: 0;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
                max-width: 100%;
                max-height: 100%;
            }
            &::after {
                content: "";
                display: block;
                height: 0;
                width: 100%;
                padding-bottom: calc(242 / 252 * 100%);
            }
        }
        &_link {
            background: $white;
            padding: 0 5px;
            height: 23px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: max-content;
            position: absolute;
            top: 12px;
            right: 12px;
            .link_text {
                font-size: size(10);
                font-weight: $font-regular;
                line-height: 11px;
                color: $black;
                text-decoration: none;
                padding-left: 9px;
                display: none;
            }
            .link_icon {
                display: flex;
                align-items: center;
            }
        }
    }
}