@import '../../../../styles/uitilities.scss';

.footer_menu {
    &_list {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        &_title {
            font-family: $font-family;
            font-size: size(18);
            font-weight: $font-bold;
            margin-bottom: 30px;
            color: $text-color;
            transition: 0.5s ease all;
            @include max(575.98) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        li {
            &:first-child {
                margin-bottom: 0 !important;
                display: block !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
            &:not(:last-of-type) {
                margin-bottom: 28px;
                @include max(767.98) {
                    margin-bottom: 22px;
                }
            }
            a {
                font-family: $font-family;
                font-size: size(18);
                font-weight: $font-regular;
                line-height: 1.16;
                color: $text-color-alt;
                opacity: 0.65;
                text-decoration: none;
                transition: 0.3s ease all;
                &:hover {
                    color: $header-menu-item-active;
                    opacity: 1;
                }
                &:global(.active) {
                    color: $header-menu-item-active !important;
                    opacity: 1;
                }
            }
        }
        &.footer_socials {
            margin-left: 12px;
            @include max(767.98) {
                width: 100%;
                margin-left: 0;
            }
            li {
                display: inline-block;
                margin-bottom: 0;
                @include min(768) {
                    transform: translateX(-10px);
                }
                &:not(:nth-of-type(2)) {
                    a {
                        padding-left: 23px;
                    }
                }
                &:not(:last-of-type) {
                    a {
                        padding-right: 23px;
                    }
                }
                &:first-of-type {
                    @include max(767.98) {
                        a {
                            padding-left: 10px;
                        }
                    }
                }
                &:nth-of-type(2) {
                    a {
                        padding-left: 13px;
                        &::before {
                            top: 55%;
                            left: 40%;
                        }
                    }
                }
                &:last-of-type {
                    a {
                        padding-right: 13px;
                        padding-left: 13px;
                        @include max(767.98) {
                            padding-right: 10px;
                        }
                        &::before {
                            left: 50%;
                            @include max(767.98) {
                                left: 55%;
                            }
                        }
                    }
                }
                a {
                    position: relative;
                    opacity: 1;
                    z-index: 1;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    @include max(575.98) {
                        padding-top: 7px;
                        padding-bottom: 7px;
                    }
                    &::before {
                        content: "";
                        width: 44px;
                        height: 44px;
                        background: $social-icon-bg;
                        position: absolute;
                        top: 55%;
                        left: 50%;
                        transform: translate(-50%, -50%) scale(0);
                        border-radius: 50%;
                        z-index: -1;
                        transition: 0.3s ease all;
                        @include max(767.98) {
                            width: 35px;
                            height: 35px;
                            left: 40%;
                            transform: translate(-50%, -50%) scale(1);
                        }
                        @include max(575.98) {
                            top: 60% !important;
                        }
                    }
                    svg {
                        path {
                            fill: $social-icon-color;
                            transition: 0.3s ease all;
                            @include max(767.98) {
                                fill: var(--social-icon-hover);
                            }
                        }
                    }
                    &:hover {
                        &::before {
                            transform: translate(-50%, -50%) scale(1);
                        }
                        svg {
                            path {
                                fill: $social-icon-hover;
                            }
                        }
                    }
                }
            }
        }
    }
}

.footer_top {
    padding: 85px 0 58px;
    background: $footer-bg;
    transition: 0.5s ease all;
    @include max(991.98) {
        padding: 70px 0;
    }
    @include max(767.98) {
        padding: 46px 0 41px;
    }
}
.footer_bottom {
    padding: 29px 0 26px;
    background: $post-footer-bg;
    transition: 0.5s ease all;
    &_wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        @include max(767.98) {
        flex-direction: column;
            justify-content: center;
        }
    }
    .copyrights,
    .designed_by {
        font-size: size(16);
        font-weight: $font-regular;
        color: $post-footer-color;
        transition: 0.5s ease all;
        @include max(767.98) {
            text-align: center;
            font-size: 12px;
        }
    }
    .copyrights {
        font-family: $font-family-lato;
    }
    .designed_by {
        @include max(767.98) {
            margin-top: 5px;
            font-size: 13px;
        }
        a {
            text-decoration: none;
            color: inherit;
            transition: 0.5s ease all;
            &:hover {
                color: $text-alt-hover;
            }
        }
    }
}

.logo_col {
    overflow: hidden;
    flex: 0 0 29.7%;
    max-width: 29.7%;
    @include max(1439.98) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 59px;
    }
    .footer_logo {
        margin: 7px 0 0;
        @include min(768) {
            transform: scale(1.17);
            transform-origin: left;
        }
        img {
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }
    }
}
.list_col_1 {
    @include max(575.98) {
        .footer_menu_list {
            display: flex;
            flex-wrap: wrap;
            li {
                flex: 0 0 50%;
                max-width: 50%;
                &:nth-of-type(1) {
                    order: 1;
                    // flex: 0 0 100%;
                    // max-width: 100%;
                }
                &:nth-of-type(2) {
                    order: 2;
                }
                &:nth-of-type(3) {
                    order: 4;
                }
                &:nth-of-type(4) {
                    order: 3;
                }
                &:nth-of-type(5) {
                    order: 5;
                }
            }
        }
    }
}
.list_col_1,
.list_col_2 {
    flex: 0 0 27%;
    max-width: 27%;
    @include max(1439.98) {
        flex: 0 0 37.5%;
        max-width: 37.5%;
    }
    @include max(991.98) {
        flex: 0 0 35%;
        max-width: 35%;
    }
    @include max(767.98) {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 62px;
    }
    @include max(575.98) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.list_col_3 {
    @include max(1439.98) {
        flex: 0 0 25%;
        max-width: 25%;
    }
    @include max(991.98) {
        flex: 0 0 30%;
        max-width: 30%;
    }
    @include max(767.98) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}