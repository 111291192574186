@import '../../styles/uitilities.scss';

.preference {
  .tab_item_title{
      font-size: size(30);
      font-weight: 500;
      @include min(992){
        margin-bottom: 10px;
      }
  }
  @include min(768) {
    padding: 50px;
    min-height: calc(100vh - 76px);
    .preference_wrap{
      padding: 42px 62px 60px 62px;
      // padding: 42px 62px 100px 62px;
    }
  }
  @include max(767.98) {
    
    .preference_wrap{
      border-radius: 0;
    }
    .tab_item_title{
      font-size: size(18);
      padding: 0 0 15px 0;
      line-height: 100%;
      border-bottom: 2px solid $dashboard-item-border;
    }
  }
  .preference_wrap{
    background-color: $dashboard-header-bg;
    color: $text-color;
    @include max(767.98) {
      min-height: calc(100vh - 52px);
    }
    .preference_dis{
      opacity: 0.7;
      font-size: size(14);
    }
    @include min(768) {
      border-radius: 12px;
    }
    @include min-max(576,767.98) {
      padding: 42px;
    }
    @include max(575.98) {
      padding: 16px;
    }
  }
  .preference_box {
    display: flex;
    justify-content: space-between;
    
    border-bottom: inset;
    border-bottom-width: 1px;
    // max-width: 1175px;
    border-color: $dashboard-item-border;
    @include min(576) {
      padding: 15px 0;
    }
    @include max(575.98) {
      padding: 13px 0;
    }
    &:nth-last-child(1){
      border-bottom: none!important;  
    }

    .preference_form_group {
      .preference_slider_round {
        border-radius: 34px;
      }

      :global(.toggle-switch) {
        width: 48px;
        height: 28px;
        :global(.slider){
          font-size: 13px;
          &:before{
            width: 23px;
            height: 23px;
          }
        }
        
      }
      .preference_box_radio_wrap{
        display: flex;
        @include min(768) {
          width: 200px;
        }
        >div{
          // padding-left: 24px;
          @include min(768) {
            flex: 0 0 50%;
            max-width: 50%;
          }
        }
        :global(.custom-checkbox) {
          span{
            color: $dashboard-menu-item;
            font-size: 17px;
            &.checkmark_checked{
              color: $text-color;
            }
          }
          
        }
      }

    }
    .preference_subtitle{
      font-weight: 500;
      letter-spacing: 0.36px;
      margin-bottom: 8px;
      @include min(576) {
        font-size: size(18);
      }
      @include max(575.98) {
        font-size: size(16);
      }
    }
    .preference_form_button {
      button {
        min-width: 135px;
        border-radius: 6px;
        font-size: 17px;
        font-weight: 400;
        color: white;
        &:focus{
          background-color: $red;
        }
        @include max(575.9){
          font-size: size(14);
          min-width: 91px;
          padding: 8px 5px;
        }
      }
    }
  }

  :global(.dropdown-menu) {
    background-color: white;
    border-radius: 10px;
    padding: 13px;
    position: relative;
    width: 100%;
    &::before {
      content: "";
      background: $white;
      position: absolute;
      left: 20px;
      width: 14px;
      height: 14px;
      @include max(767.98) {
        left: inherit;
        right: 20px;
      }
    }
    &[x-placement='bottom-start'] {
      transform: translate(0, 54px) !important;
      &::before {
        top: 0;
        transform: translateY(-50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 0 0 5px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }
    &[x-placement='bottom-end'] {
      transform: translate(0, 54px) !important;
      &::before {
        top: 0;
        transform: translateY(-50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 0 0 5px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }
    &[x-placement='top-start'] {
      &::before {
        bottom: 0;
        transform: translateY(50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 5px 0 0;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }
    a {
      font-size: size(17);
      font-weight: $font-regular;
      padding: 6px 10px;
      border-radius: 6px;
      overflow: hidden;
      &:hover,
      &:focus {
        color: $text-alt;
        background-color: $dropdown-bg-hover;
      }
    }
  }
  .language_bar_toggle{
    background-color: $body-background;
    color: $text-color;
    font-size: size(17);
    font-weight: 400;
    text-align: left;
    padding: 13px;
    width: 160px;
    height: 45px;
    border: 1px solid #A09EA6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:active,
    &:focus,
    &:hover{
      // background-color: rgba(0,0,0,0.02);
      color: $text-color;
    }
    &:after{
      border: 1px solid transparent;
      border-left-color: $text-alt;
      border-bottom-color: $text-alt;
      transform: rotate(-45deg);
      width: 6px;
      height: 6px;
    }
  }
  :global(.show.dropdown){
    .language_bar_toggle{
      background-color: transparent;
      color: $text-alt;
    }
  }
  .pr_3{
    padding-right: 1em;
  }
  .pr_4{
    padding-right: 2em;
  }
  :global(.btn_darkmode_theme_toggle){
      position: relative;
      top: 0;
      width: 110px;
      height: 33px;
      box-shadow: none;
      border: 1px solid #E0E2E8;
      border-radius: 3px;
    svg{
      width: 22px;
      height: 22px;
      top: 0;
      bottom: 0;
    }
    span{
      display: block;
      font-size: size(14);
      margin-left: 10px;
      width: 59px;
      padding-left: 5px;
      text-align: center;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #A8A8A84A;
    }
    :global(.theme-mode) {
      display: flex;
      flex-direction: row-reverse;
    }
    :global(.theme-light) {
      span {
        margin-left: 0;
        padding-left: 0;
        margin-right: 10px;
        padding-right: 5px;
        border-left: 0;
        border-right: 1px solid #A8A8A84A;
      }
    }
  }

}


.language_select_btn{
  border: none;
  width: 20px;
  height: 75%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:before,
  &:after{
    content: "";
    display: block;
    border-radius: 5px;
    height: 13px;
    width: 2px;
    background-color: $text-color;
  }
  &:before{
    transform: rotate(-38deg) translate(0px, 2px);
  }
  &:after{
    transform: rotate(38deg) translate(0px, -2px);
  }
}


:global(body.dark){
  .language_bar_toggle{
    border: 1px solid $body-background;
    &:after{
      border-left-color: white;
      border-bottom-color: white;
    }
    &:hover{
      color: white;
      background-color: black;
    }
  }
  :global(.show.dropdown){
    .language_bar_toggle{
      color: white;
      background-color: black;
      border: 1px solid $body-background;
    }
  }
}
.language_menu_mob{
  z-index: 1100;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.7);
}


.delete_account {
  padding: 50px 46px;
  @include max(575.98) {
    padding: 15px;
  }
  &_title {
    font-size: size(24);
    font-weight: $font-bold;
    color: $text-color-alt;
    text-align: center;
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  &_description {
    font-size: size(16);
    font-weight: $font-regular;
    color: $text-color-alt;
    opacity: 0.61;
    line-height: 28px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  :global(.form-group) {
    margin-bottom: 40px;
  }
  :global(.error-msg) {
    color: rgb(255, 98, 97);
  }
  &_btn_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button {
      flex: 0 0 47.5%;
      max-width: 47.5%;
      margin: 0 10px;
      @include max(575.98) {
        padding: 15px;
        min-width: inherit;
      }
      @include max(374.98) {
        flex: 0 0 100%;
        max-width: 100% !important;
        &:first-child {
          margin-bottom: 10px;
        }
      }
      @include min(576) {
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    :global(.btn-red:focus) {
      background-color: #FC4343;
      color: #fff;
    }
  }
}
.field_error {
  input {
    border-color: rgb(255, 98, 97);
  }
}

:global(.delete_account_modal .modal-dialog) {
  max-width: 474px;
}
:global(.delete_account_modal .modal-body .btn) {
  width: 100%;
  max-width: 184px;
}
