@import "../../styles/uitilities.scss";

.tree {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: auto;
  :global(#tree) {
    width: 100%;
    height: 100%;
    overflow: auto !important;
  }
  :global(.cardWrapper) {
    background-color: $dashboard-header-bg;
    box-shadow: 2px 2px 5px rgba(192, 196, 206, 0.49);
    border-radius: 10px;
    padding: 13px;
    box-sizing: border-box;
    cursor: pointer;
  }
  :global(foreignObject) {
    overflow: unset;
  }
  :global(#addNew) {
    position: absolute;
    bottom: -10px;
    width: 100%;
    height: 40px;
    background-position: center;
    background-size: auto;
    background-color: transparent;
    border: none;
    background-repeat: no-repeat;
    opacity: 0;
    z-index: 9;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
  }
  :global(#addNew > span) {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #23cc2b;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    bottom: 10px;
    pointer-events: none;
  }

  :global(#addNew > span::after) {
    position: absolute;
    width: 14px;
    height: 2px;
    background-color: #fff;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    transition: 0.3s background-color ease-in-out;
  }
  :global(#addNew > span::before) {
    position: absolute;
    width: 14px;
    height: 2px;
    background-color: #fff;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    transition: 0.3s background-color ease-in-out;
  }
  :global(#addNew::after) {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 120%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='106.334' height='41.347' viewBox='0 0 106.334 41.347'%3E%3Cdefs%3E%3Cfilter id='Path_76531' x='0' y='0' width='106.334' height='41.347' filterUnits='userSpaceOnUse'%3E%3CfeOffset dx='2' dy='2' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='2.5' result='blur'/%3E%3CfeFlood flood-color='%23bfc4ce' flood-opacity='0.49'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3C/filter%3E%3C/defs%3E%3Cg transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(%23Path_76531)'%3E%3Cpath id='Path_76531-2' data-name='Path 76531' d='M-4619.16-4735.3a36.637,36.637,0,0,1,17.479,2.537c11.661,5.259,9.106,17.39,29.16,18.5,12.914,0,21.019-14.055,25.02-17.042,3.889-3.348,8.786-3.414,17.011-3.6s-5.225-5.707-5.225-5.707l-75.039.706Z' transform='translate(4624.66 4746.11)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center -23px;
    background-size: 160px;
    background-repeat: no-repeat;
    z-index: -2;
    opacity: 0;
    transition-delay: 0.3s;
    transition: 0s all ease-in-out;
    transform: scale(0.75);
    pointer-events: none;
  }
  :global(#addNew::before) {
    content: "";
    position: absolute;
    left: 0;
    top: -4px;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='91.334' height='26.347' viewBox='0 0 91.334 26.347'%3E%3Cpath id='Path_76530' data-name='Path 76530' d='M-4619.16-4735.3a36.637,36.637,0,0,1,17.479,2.537c11.661,5.259,9.106,17.39,29.16,18.5,12.914,0,21.019-14.055,25.02-17.042,3.889-3.348,8.786-3.414,17.011-3.6s-5.225-5.707-5.225-5.707l-75.039.706Z' transform='translate(4619.16 4740.608)' fill='%23fff'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    z-index: -1;
    pointer-events: none;
  }
  :global(.mainWrapper:hover) :global(#addNew) {
    opacity: 1;
    bottom: -31px;
  }
  :global(.mainWrapper:hover) :global(#addNew::after) {
    opacity: 1;
    transition-delay: 0.5s;
    transition: 1s all ease-in-out;
  }
  :global(.image-wrapper) {
    width: 140px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 15px;
  }

  :global(.image-container) {
    width: 100%;
    position: relative;
    padding-bottom: 100%;
  }
  :global(.image-container.no-image) {
    background-color: #f2f3f5;
  }

  :global(.image-container.no-image::after) {
    position: absolute;
    width: 30px;
    height: 2px;
    background-color: #252533;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    transition: 0.3s background-color ease-in-out;
  }
  :global(.image-container.no-image::before) {
    position: absolute;
    width: 30px;
    height: 2px;
    background-color: #252533;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    transition: 0.3s background-color ease-in-out;
  }
  :global(.image-container img) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  :global(.content-wrapper) {
    width: 100%;
    position: relative;
  }

  :global(.content-head) {
    width: 100%;
    text-align: center;
  }
  :global(.content-head h3) {
    font-size: 14px;
    line-height: 1.5;
    color: $text-color-alt;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 3px;
  }

  :global(.content-head p) {
    font-size: 12px;
    line-height: 1.5;
    color: $text-label-grey;
    font-weight: 400;
    margin: 0px;
    margin-bottom: 0px;
  }

  :global(html, body) {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-family: Helvetica;
    font-family: "Ubuntu", sans-serif;
  }

  // :global(#root) {
  //   width: 100%;
  //   height: 100%;
  // }

  :global(.bft-light) {
    background-color: $dashboard-bg !important;
  }

  :global(.link path) {
    stroke-width: 4px;
    stroke: #d3d5db;
    stroke-linejoin: unset !important;
  }

  :global(#dot circle) {
    fill: #23cc2b;
    stroke: #f6f6f6;
    stroke-width: 4px;
    // r: 8px;
  }
  .treeControls {
    display: flex;
    align-items: center;
    margin: 0 -11px;
    position: fixed;
    bottom: 47px;
    right: 74px;
    @include max(1279.98) {
      right: 16px;
      bottom: 70px;
    }
    :global(.btn) {
      margin: 0 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: size(18);
      font-weight: $font-medium;
      color: #252533;
      @include max(1279.98) {
        border-radius: 50%;
        width: 46px;
        height: 46px;
        padding: 0;
        span {
          display: none;
        }
        svg {
          margin-right: 0 !important;
        }
      }
      &:hover,
      &:focus {
        color: #fff;
        background-color: $btn-primary-hover;
        border-color: $btn-primary-hover;
        box-shadow: 0 3px 5px rgba(#BFC4CE, 0.36) !important;
        svg {
          path {
            fill: #fff;
          }
        }
      }
      &:first-child {
        &:hover,
        &:focus {
          svg {
            path {
              fill: #fff;
              &:nth-child(1),
              &:nth-child(3),
              &:nth-child(5) {
                fill: $btn-primary-hover;
              }
            }
          }
        }
      }
      &:nth-child(2) {
        &:hover {
          path {
            fill: #fff;
          }
        }
      }
      svg {
        margin-right: 15px;
      }
    }
    .btnDiv {
      display: flex;
      align-items: center;
      border-radius: 23px;
      box-shadow: 0 3px 5px rgba(#bfc4ce, 0.36);
      margin: 0 11px;
      .zoomInput {
        padding: 13px 14px;
        background-color: #fff;
        border: none;
        max-width: 75px;
        width: 100%;
        font-size: size(18);
        font-weight: $font-medium;
        color: #252533;
        height: 48px;
      }
      .btn_modify {
        width: 53px;
        min-height: 48px;
        border: none;
        background-color: #fff;
        &:hover {
          background-color: $btn-primary-hover;
          border-color: $btn-primary-hover;
          svg {
            path {
              fill: #fff;
            }
          }
        }
        &:first-of-type {
          border-radius: 23px 0 0 23px;
          border-right: 1px solid rgba(#a2a4ac, 0.6);
        }
        &:last-of-type {
          border-radius: 0 23px 23px 0;
          border-left: 1px solid rgba(#a2a4ac, 0.6);
        }
      }
    }
  }
}

:global(.dark #addNew::before) {
  filter: invert(82%) sepia(3%) saturate(4350%) hue-rotate(202deg) brightness(98%) contrast(90%);
}
:global(.dark :is(.cardWrapper, .btn-curved, .btn-curved+div, .btn.btn-curved:focus, .btn-div:focus)) {
  box-shadow: none !important;
}