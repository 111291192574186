@import "../../styles/uitilities.scss";

:global(#myvideo) {
  height: 100% !important;
  pointer-events: none;

  > div {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 790px;
    margin: 0 auto;
    border-radius: 10px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      // object-fit: cover;
      max-width: 100%;
      max-height: 100%;
      border-radius: 6px;
      overflow: hidden;
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: calc(9 / 16 * 100%);
    }
  }
}

:global(.full_screen > div) {
  max-width: 100% !important;
}

:global(.full_screen + div) {
  max-width: 100% !important;
}

.hide_control .controls {
  opacity: 0;
  pointer-events: none;
  transition: 0.3s all ease-in-out;
  transition-delay: 0.5s;
}
.controls {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 9;
  padding: 0 30px;

  &_seek {
    @include max(424.98) {
      margin-bottom: -6px;
    }
    :global(.ant-slider.ant-slider-horizontal) {
      margin-left: 0;
      margin-right: 0;
    }
    :global(.ant-slider:hover .ant-slider-handle) {
      border: 2px solid #f03b0f;
      background-color: #f03b0f;
    }
    :global(.ant-slider-handle) {
      border: 2px solid #fff;
      transition: 0.3s ease all;
      &:hover {
        border: 2px solid #f03b0f;
        background-color: #f03b0f;
      }
    }
    :global(.ant-slider:hover .ant-slider-handle) {
      border-color: #f03b0f !important;
    }
  }
  &_other_controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 23px 0;
    @include max(991.98) {
      margin: 12px 0;
    }
    @include max(575.98) {
      margin: 0;
    }
  }
  &_left,
  &_right {
    display: flex;
    align-items: center;
  }
  .btn_control {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    svg {
      @include max(575.98) {
        width: 12px;
        height: 12px;
      }
      path {
        transition: 0.3s ease all;
      }
    }
    &:hover {
      svg {
        path {
          fill: #f03b0f;
        }
      }
    }
  }
  &_volume {
    display: flex;
    align-items: center;
    :global(.ant-slider.ant-slider-horizontal) {
      width: 75px;
      margin: 0 10px;
      @include max(575.98) {
        width: 60px;
        position: absolute;
        top: -100%;
        left: -20px;
        transform: rotate(-90deg);
      }
    }
    :global(.ant-slider-track) {
      background-color: #ffffff;
      &:hover {
        background-color: #f03b0f;
      }
    }
    :global(.ant-slider:hover .ant-slider-handle) {
      border: 1px solid #f03b0f !important;
      background-color: #f03b0f !important;
    }
    :global(.ant-slider:hover .ant-slider-track) {
      background-color: #ffffff !important;
    }
    :global(.ant-slider-handle) {
      border: 1px solid #ffffff;
      transition: 0.3s ease all;
      &:hover {
        border: 1px solid #f03b0f;
      }
    }
  }
  &_duration {
    font-size: size(12);
    font-weight: $font-regular;
    color: #fff;
  }
  &_item {
    padding: 0 12px;
    @include max(575.98) {
      padding: 0 8px;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.player_custom {
  width: 100%;
  position: relative;
  &.hide_control {
    &:hover {
      .controls {
        transition-delay: 0s;
        pointer-events: all;
        opacity: 1;
      }
    }
  }
}

:global(#myvideo:not(.full_screen)) {
  border: 11px solid $white;
  border-radius: 20px;
  box-shadow: -2px 0 8px #07145411;
  @include max(767.98) {
    border: 4px solid $white;
    border-radius: 10px;
  }
}
:global(.full_screen ~ .player-controls) {
  max-width: 100%;
}

.icon_video_play_toggle {
  position: absolute;
  top: 50%;
  left: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  opacity: 0;
  transition: 0.5s all;
  background-color: #1e1b1b73;
  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
  &.video_unplayed {
    opacity: 1 !important;
    ~ .controls {
      opacity: 0 !important;
    }
  }
}

.anim_enabled {
  &.btn_played {
    animation: mymove 0.4s;
    animation-timing-function: ease-in-out;
  }
  &.btn_paused {
    animation: mymove1 0.4s;
    animation-timing-function: ease-in-out;
  }
}

@keyframes mymove {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.7);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.3);
  }
}

@keyframes mymove1 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.7);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.3);
  }
}