@import "../../../styles/uitilities.scss";

// .slideshow_section {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   flex-direction: column;
//   transition: all 1s;
//   // :global(.swiper-wrapper) {
//   //   transition-duration: 0s !important;
//   // }

//   :global(.fade) {
//     animation: fade 4s ease 1 forwards;
//     transition: 5s ease all;
//   }
//   // :global(.swiper-slide-active) {
//   //   opacity: 1 !important;
//   // }
//   :global(.bounce) {
//     opacity: 1;
//     animation: bounce 3s ease 1 forwards;
//     transition: 2s ease all;
//   }
//   :global(.zoom-out) {
//     animation: zoom-out 3s ease 1 forwards;
//     transition: 2s ease all;
//   }
//   &_slides_nav {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }
//   @keyframes fade {
//     0% {
//       opacity: 0;
//     }
//     100% {
//       opacity: 1;
//     }
//   }

//   @keyframes bounce {
//     0% {
//       transform: scale(1, 1) translateY(0);
//     }
//     10% {
//       transform: scale(1.1, 0.9) translateY(0);
//     }
//     30% {
//       transform: scale(0.9, 1.1) translateY(-100px);
//     }
//     50% {
//       transform: scale(1, 1) translateY(0);
//     }
//     57% {
//       transform: scale(1, 1) translateY(-7px);
//     }
//     64% {
//       transform: scale(1, 1) translateY(0);
//     }
//     100% {
//       transform: scale(1, 1) translateY(0);
//     }
//   }
//   @keyframes zoom-out {
//     0% {
//       transform: scale(1.5, 1.5);
//     }
//     100% {
//       transform: scale(1, 1);
//     }
//   }
//   :global(.non) {
//     transition: 1s ease all;
//   }
//   .imgContainer {
//     max-width: 600px;
//   }

//   .options {
//     display: flex;
//     justify-content: space-between;
//     padding: 0;
//     align-items: center;
//     margin-right: 50px;
//     width: 300px;
//     border: 2px solid #d3d5db;
//     border-radius: 20px;
//     height: 75px;
//     background-color: #d3d5db45;

//     & > ul {
//       // display: flex;
//       // justify-content: center;
//       // padding: 0;
//       // align-items: center;
//       // height: 60px;
//       // overflow: hidden;
//       & > div {
//         // margin: 10px;
//         // display: inline-block;
//         // width: 100%;
//         list-style: none;
//       }

//       & > li {
//         list-style: none;
//       }
//     }
//   }

//   .section_two {
//     display: flex;
//     justify-content: space-between;
//     margin-top: 70px;
//     width: 80px;
//     height: 80px;
//     background: rgb(197, 194, 194);
//     border-radius: 50%;
//   }

//   .img_selection {
//     border-top-style: solid;
//     border-color: grey;
//     border-width: 1px;
//     width: 600px;

//     & > ul {
//       display: flex;
//       justify-content: space-around;

//       & > li {
//         list-style: none;

//         & > img {
//           width: 84px;
//           height: 84px;
//           border: 3px solid #23cc2be3;
//           border-radius: 50%;
//         }
//       }
//     }
//   }
// }

// // img {
// //   width: 100%;
// //   height: 100%;

// //   &.anim {
// //     opacity: 0;
// //     transform: translateY(35px);
// //     transition: transform 0.75s cubic-bezier(0.38, 0.005, 0.215, 1),
// //       opacity 0.75s cubic-bezier(0.38, 0.005, 0.215, 1);

// //     &.vissible {
// //       opacity: 1;
// //       transform: translateY(0px);
// //     }
// //   }
// // }
// // :global {
// //   .swiper-button-next {
// //     display: none;
// //   }
// //   .swiper-button-prev {
// //     display: none;
// //   }
// // }
// // :global {
// //   .swiper {
// //     width: 600px;
// //     padding-top: 50px;
// //     padding-bottom: 50px;
// //   }

// //   .swiper-slide {
// //     background-position: center;
// //     background-size: cover;
// //     width: 300px;
// //     height: 300px;
// //   }
// // }

// // .slider_wrap {
// //   padding-top: calc((9 / 16) * 100%);
// //   position: relative;

// //   & > img {
// //     position: absolute;
// //     left: 0;
// //     width: 100%;
// //     top: 0;
// //     height: 100%;
// //     object-fit: cover;
// //     cursor: pointer;
// //   }
// // }
// // .slider_active {
// //   border: 3px solid rgb(0, 255, 0);
// // }
// // .slider_bottom {
// //   & > img {
// //     width: 84px;
// //     height: 84px;
// //     border-radius: 50%;
// //   }

// //   .deleteImg {
// //     position: absolute;
// //     right: 30px;
// //     top: -10px;
// //     width: 30px;
// //     height: 30px;
// //     transform: rotate(45deg);
// //     border-radius: 50%;
// //     background-color: rgba(0, 0, 0, 0.037);
// //     display: flex;
// //     justify-content: center;
// //     align-items: center;
// //     color: black;
// //     font-size: 25px;
// //     font-weight: 200;
// //     cursor: pointer;
// //   }
// // }

.slideshow {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 55px;
    position: relative;
    border-bottom: 1px solid $dashboard-header-border;
    margin-top: 10px;

    @include max(1199.98) {
      padding: 12px 16px;
    }

    :global(.btn) {
      min-width: inherit;
      height: 35px;
      font-size: size(17);
      padding: 8px 25px;
      @include max(575.98) {
        font-size: size(15);
        padding: 6px 18px;
      }
    }

    .back_button {
      border: none;
      background-color: transparent;
      font-size: size(17);
      font-weight: $font-regular;
      display: flex;
      align-items: center;
      color: $text-color-alt;

      svg {
        margin-right: 10px;

        path {
          fill: $text-color-alt;
        }
      }
    }

    .slideshow_name {
      font-size: size(18);
      font-weight: $font-medium;
      color: $text-color-alt;

      @include min(767.98) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 200px;
      }

      input {
        border-color: transparent;
        background: inherit;
        text-align: center;

        @include max(767.98) {
          width: 100%;
        }
      }
    }

    .slideshow_actions {
      display: flex;

      .slideshow_save {
        margin-right: 10px;
      }
    }
  }

  &_display {
    max-width: 1100px;
    margin: 0 auto;
    @include min(1440) {
      max-width: 1084px;
      padding: 0 16px;
    }
    @include min(1280) {
      padding: 0 30px;
    }
    @include max(1279.98) {
      max-width: 80vw;
    }
    @include min(768) {
      padding-top: 15px;
    }
    @include max(767.98) {
      margin-top: 40px;
    }
    :global(.fade-effect) {
      img {
        animation: fade 0.54s ease-out 1 forwards;
      }
    }

    :global(.bounce) {
      img {
        opacity: 1;
        animation: bounce 3s ease-out 1 forwards;
      }
    }

    :global(.zoom-out) {
      img {
        animation: zoom-out 0.54s ease-out 1 forwards;
      }
    }

    :global(.non) {
      img {
        transition: 1s ease-out all;
      }
    }

    :global(.mySwiper2 .swiper-wrapper) {
      transition-duration: 0.01s !important;
    }
  }

  &_image {
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
    background: $black;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }

    &::after {
      content: "";
      display: block;
      height: 0;
      width: 100%;
      padding-bottom: calc(621 / 1084 * 100%);
    }
  }

  @include max(767.98) {
    &_duration {
      order: 1;
      text-align: left;
    }

    &_options {
      order: 3;
      flex: 0 0 100%;
      max-width: 343px;
      margin-top: 37px;
      margin-left: auto;
      margin-right: auto;
    }

    &_slides_count {
      order: 2;
      text-align: right;
    }
  }

  &_duration,
  &_slides_count {
    font-size: size(14);
    font-weight: $font-regular;
    color: $text-color-alt;

    @include max(767.98) {
      flex: 0 0 50%;
    }
  }

  &_controls {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 0;

    @include max(767.98) {
      flex-wrap: wrap;
    }
  }

  &_options {
    background: #d3d5db50;
    border: 1px solid #d3d5db;
    border-radius: 20px;
    padding: 11px 26px;

    @include max(767.98) {
      padding: 9px 21px;
      border-radius: 13px;
    }
    :global(.dropdown-menu) {
      background-color: $white;
      border-radius: 10px;
      padding: 13px;
      position: relative;
      // transition: 0.5s ease all;
      &::before {
        content: "";
        background: $white;
        position: absolute;
        left: 20px;
        width: 14px;
        height: 14px;
      }
      &[x-placement="bottom-start"] {
        transform: translate(-20px, 48px) !important;
        &::before {
          top: 0;
          transform: translateY(-50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 0 0 5px;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
          // transition: 0.5s ease all;
        }
      }
      &[x-placement="top-start"] {
        transform: translate(-20px, -48px) !important;
        &::before {
          bottom: 0;
          transform: translateY(50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 5px 0 0;
          border-top: 1px solid $border-color;
          border-right: 1px solid $border-color;
          // transition: 0.5s ease all;
        }
      }
    }
    &_list {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      @include max(767.98) {
        justify-content: space-around;
      }

      li {
        padding: 0 10px;

        svg {
          cursor: pointer;
        }

        > div {
          &:not(.slideshow_options_play) {
            svg {
              path {
                fill: $black;
              }
            }
          }
        }
      }
    }

    &_filter {
      :global(.dropdown-menu a) {
        &:active {
          background-color: #e9ecef;
          color: $black;
        }
      }
      .filter_icon {
        :global(svg) {
          fill: #2c2c34;
          transform: rotate(270deg);
          transform-origin: center;
        }
      }

      .filter_dropdown {
        min-width: inherit;
        padding: 0;
        background-color: transparent;
        border: none;

        &::after {
          display: none;
        }
      }
    }

    &_music {
      svg {
        transform: rotate(180deg);
        transform-origin: center;
      }

      :global(.dropdown-toggle) {
        min-width: inherit;
        padding: 0;
        background-color: transparent;
        border: none;

        &::after {
          display: none;
        }
      }

      :global(.dropdown-menu a) {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        span {
          display: none;
        }

        &:hover span {
          display: block;
        }

        &:hover img {
          display: none;
        }
        &:active {
          background-color: #e9ecef;
          color: $black;
        }

        svg {
          transform: rotate(1deg);
          // margin-right: 10px;
        }
      }
    }

    &_play {
      :global(svg) {
        fill: $btn-secondary;
        width: 44px;
        height: 44px;

        @include max(767.98) {
          width: 40px;
          height: 40px;
        }
      }
    }

    &_fullscreen {
      svg {
        path {
          fill: $text-color;
        }
      }
    }
  }

  &_slides_nav {
    border-top: 1px solid $dashboard-header-border;
    padding: 18px 0;
    width: calc(100vw - 307px);
    @include max(1439.98) {
      width: calc(100vw - 250px);
    }
    @include max(1279.98) {
      width: 100vw;
    }
    @include max(575.98) {
      padding-left: 16px;
      padding-right: 16px;
    }
    &_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 31px;
      @include max(575.98) {
        margin-right: 0;
      }
      :global(.swiper-slide-thumb-active) {
        .slides_nav_image {
          border: 3px solid #23cc2b;
        }
      }
    }
  }

  &_nav_swiper {
    flex: 0 0 90%;
    margin-right: 30px;
    padding: 20px 0;
    @include max(575.98) {
      margin-right: 0;
      flex: 0 0 87%;
    }

    :global(.swiper-slide) {
      width: 84px;
      height: 84px;
      @include max(575.98) {
        width: 54px;
        height: 54px;
      }
    }

    .delete_img {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: $rearrange-close-bg;
      border: none;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .slides_nav_image {
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      margin-bottom: 0;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
      }

      &::after {
        content: "";
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: 100%;
      }
    }
  }

  &_add_file {
    flex: 0 0 36px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    border: 2px solid $text-color;
    cursor: pointer;
    transition: 0.3s ease all;
    @include max(575.98) {
      margin-left: 10px;
    }
    &:hover {
      transform: rotate(90deg);
      transform-origin: center;
    }

    label {
      padding: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    svg {
      transform: rotate(45deg);
      transform-origin: center;

      path {
        fill: $text-color-alt;
      }
    }
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes bounce {
    0% {
      transform: scale(1, 1) translateY(0);
    }

    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }

    30% {
      transform: scale(0.9, 1.1) translateY(-100px);
    }

    50% {
      transform: scale(1, 1) translateY(0);
    }

    57% {
      transform: scale(1, 1) translateY(-7px);
    }

    64% {
      transform: scale(1, 1) translateY(0);
    }

    100% {
      transform: scale(1, 1) translateY(0);
    }
  }

  @keyframes zoom-out {
    0% {
      transform: scale(1.5, 1.5);
    }

    100% {
      transform: scale(1, 1);
    }
  }
}

.playerProgress {
  font-size: 10px;
  display: flex;
  align-items: center;

  p {
    margin: 0px;
  }
}

.listItems {
  height: 100px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.playerOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;

  svg {
    transform: rotate(1deg);
    margin-bottom: 5px;
  }
}

.playerControls {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  border-top: 1px solid #bfbfc3;

  .audioName {
    //position: absolute;
    font-size: 12px;
    margin: 0px;
  }
}

.musicVisualizer {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.progress {
  cursor: pointer;
}

.noDataSection {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }

  &::after {
    content: "";
    display: block;
    height: 0;
    width: 100%;
    padding-bottom: calc(621 / 1084 * 100%);
  }
}
