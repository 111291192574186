@import "../../../styles/uitilities.scss";


.User_profile_gallery{
    &_right_side,
    &_main_col{
        width: 100%;
    }
    &_sec{
        width: 100%;
        background-color: $dashboard-header-bg;
        padding: 30px 28px;
        @include min(768){
            border-radius: 10px;
            margin: 25px 0;
        }
        @include max(767.98){
            margin: 10px 0;
        }
    }

}