@import '../../styles/uitilities.scss';

.family_users {
  padding: 22px 20px;
  // width: 100%;
  border: none;
  border-radius: 10px;
  width: 235px;
  @include max(1439.98) {
    width: 178px;
  }
  background: linear-gradient(
    0deg,
    rgba(249, 229, 0, 1) 0%,
    rgba(92, 196, 127, 1) 73%
  );
  &_title {
    font-size: size(15);
    font-weight: $font-medium;
    line-height: 17px;
    color: #fff;
    margin-bottom: 6px;
  }
  &_price {
    font-size: size(14);
    font-weight: $font-regular;
    line-height: 17px;
    margin-bottom: 9px;
    color: #fff;
    span {
      font-size: size(31);
      font-weight: $font-bold;
      line-height: 41px;
    }
  }
  &_expiry {
    font-size: size(12);
    font-weight: $font-regular;
    line-height: 15px;
    margin-bottom: 18px;
    color: #fff;
  }
  &_text {
    font-size: size(14);
    font-weight: $font-regular;
    line-height: 21px;
    margin-bottom: 13px;
    color: #fff;
  }
  :global(.btn) {
    min-width: 100%;
  }
}