@import "../../../../src/styles/uitilities.scss";

.loggedin_header {
  width: 100vw;
  padding: 0;
  background-color: $dashboard-header-bg;
  padding: 13px 0;
  position: fixed;
  top: 0;
  z-index: 999;
  animation: navbarAnim 300ms ease-out;
  box-shadow: 0 2px 9px #07145412;
  @include max(575.98) {
      z-index: 99;
  }
  // :global(.dropdown-menu[x-placement="bottom-start"]) {
  //   transform: translate3d(0px, 55px, 0px) !important;
  // }
  // :global(.dropdown-menu[x-placement="bottom-start"]::before) {
  //   @include max(767.98) {
  //     right: inherit;
  //     left: 12px;
  //   }
  // }
  :global(.dropdown-menu) {
    background-color: $white;
    border-radius: 10px;
    padding: 13px;
    position: relative;
    right: 0 !important;
    left: inherit !important;
    transition: 0.5s ease all;
    @include max(1279.98) {
      right: inherit !important;
      left: 0;
    }
    &::before {
      content: "";
      background: $white;
      position: absolute;
      right: 20px;
      width: 14px;
      height: 14px;
      @include max(1279.98) {
        right: inherit;
        left: 20px;
      }
    }
    &[x-placement='bottom-start'] {
      transform: translate3d(0px, 55px, 0px) !important;
      &::before {
        top: 0;
        transform: translateY(-50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 0 0 5px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        // transition: 0.5s ease all;
      }
    }
    &[x-placement='top-start'] {
      transform: translate(0px, -64px) !important;
      &::before {
        bottom: 0;
        transform: translateY(50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 5px 0 0;
        border-top: 1px solid $border-color;
        border-right: 1px solid $border-color;
        // transition: 0.5s ease all;
      }
    }
    a {
      font-size: size(17);
      font-weight: $font-regular;
      padding: 6px 10px;
      border-radius: 6px;
      overflow: hidden;
      transition: 0.5s ease all;
      &:hover,
      &:focus {
        color: $text-alt;
        background-color: $dropdown-bg-hover;
      }
    }
  }
  :global(.search_bar_search_input) {
    transition: none;
  }
  :global(.search_bar_search_dropdown button) {
    transition: none;
    &:focus {
      color: #fff;
      background-color: $btn-primary-hover;
      border-color: $btn-primary-hover;
    }
    &:hover,
    &:focus {
      background-color: transparent;
      color: $text-color-alt;
      &::after {
        border-right: 2px solid #707070;
        border-bottom: 2px solid #707070;
      }
    }
  }
  :global(.search_bar_search_dropdown .show button) {
    color: $text-color-alt;
  }
  &_left {
    display: flex;
    justify-content: space-between;
    max-width: calc(100% - 500px);
    width: 990px;
    :global(.search_bar_search) {
      margin: 0 auto;
      max-width: 513px;
      width: 100%;
    }
  }
  &_logo {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    &:first-of-type {
      display: block;
    }
    &:last-of-type {
      display: none;
    }
  }
  &_container {
    @include min(576) {
      padding-left: 36px;
      padding-right: 36px;
    }
    @include max(575.98) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    .btn_create_tree {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 13px;
      height: 50px;
      &:focus,
      &:active {
        color: white;
      }
      svg {
        margin-right: 15px;
        path {
          fill: #ffffff;
          transition: 0.5s ease all;
        }
      }
    }
    .bell_icon {
      padding: 0 0 0 48px;
      position: relative;
      @include max(1599.98) {
        padding: 0 0 0 30px;
      }
      &::after {
        content: "";
        position: absolute;
        top: 5px;
        right: 1px;
        width: 10px;
        height: 10px;
        background: #f65543;
        border: 2px solid $dashboard-header-bg;
        border-radius: 50%;
        overflow: hidden;
        transform: scale(0);
        transition: 0.3s ease all;
      }
      &.active {
        svg {
          animation: bellShake 1.3s ease infinite forwards;
          transform-origin: top center;
        }
        &::after {
          transform: scale(1);
        }
      }
      svg {
        path {
          fill: $text-color-alt;
          transition: 0.5s ease all;
        }
      }
    }
    :global(.dropdown.show) {
      .profile_dropdown {
        background-color: transparent;
        border-color: transparent;
      }
    }
    :global(.dropdown-menu) {
      background-color: $white;
      border-radius: 10px;
      padding: 13px;
      position: relative;
      right: 0 !important;
      left: inherit !important;
      transition: 0.5s ease all;
      &::before {
        content: "";
        background: $white;
        position: absolute;
        right: 30px;
        transition: 0.5s ease all;
      }
      a {
        font-size: size(17);
        font-weight: $font-regular;
        padding: 6px 10px;
        border-radius: 6px;
        overflow: hidden;
        transition: 0.5s ease all;
        &:hover,
        &:focus {
          color: $text-alt;
          background-color: $dropdown-bg-hover;
        }
      }
    }
    .profile {
      &_dropdown {
        display: flex;
        align-items: center;
        background-color: transparent;
        border-color: transparent;
        padding: 0 20px 0 48px;
        transition: 0.5s ease all;
        @include max(1599.98) {
          padding: 0 20px 0 30px;
        }
        &:active {
          background-color: transparent;
          border-color: transparent;
        }
        &::after {
          position: absolute;
          top: 14px;
          right: 0;
          border-top: 7px solid $border-color-2;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          transition: 0.5s ease all;
        }
      }
      &_image {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        margin-bottom: 0;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          max-width: 100%;
          max-height: 100%;
          transition: 1s ease all;
        }
        &::after {
          content: "";
          display: block;
          height: 0;
          width: 100%;
          padding-bottom: 45%;
        }
      }
      &_details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 11px;
        .username {
          font-size: size(16);
          font-weight: $font-medium;
          padding-bottom: 3px;
          color: $profile-details-color;
          transition: 0.5s ease all;
        }
        .ownership {
          font-size: size(14);
          font-weight: $font-regular;
          color: $profile-details-color;
          opacity: 0.58;
          transition: 0.5s ease all;
        }
      }
    }
  }
  :global(.search_bar_search) {
    border: 1px solid $searchbar-border;
    transition: none;
  }
  @include min-max(992, 1350) {
    .loggedin_header {
      &_logo {
        padding-right: 15px;
      }
      &_nav {
        max-width: calc(100% - 399px);
        padding-right: 15px;
        .bell_icon {
          padding-left: 15px;
        }
        .profile_dropdown {
          padding-left: 12px;
        }
        .btn_create_tree {
          min-width: 160px;
        }
      }
    }
  }
}
.loggedin_header_wrap_mob {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .profile_dropdown_mob {
    padding: 0px;
    min-width: 25px;
    background-color: transparent;
    border: none;
    border-radius: 30px;
    overflow: hidden;
    transition: 0.5s ease all;
    .profile_image {
      width: 36px;
      height: 36px;
      margin-bottom: 0px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &:after {
      display: none;
    }
  }
  .bell_icon {
    display: inline-block;
    position: relative;
    &.active {
      &:after {
        content: "";
        position: absolute;
        top: 5px;
        right: 1px;
        width: 10px;
        height: 10px;
        background: #f65543;
        border: 2px solid $dashboard-header-bg;
        border-radius: 50%;
        transform: scale(1);
        transition: 0.3s ease all;
      }
      svg {
        animation: bellShake 1.3s ease infinite forwards;
        transform-origin: top center;
      }
    }
  }
}
.header_right_btn_wrap_mob {
  display: flex;
  .search_bar_search_icon_mob {
    padding-left: 10px;
    padding-right: 10px;
    svg {
      path {
        fill: $text-color;
        transition: 0.5s ease all;
      }
    }
  }
  .bell_icon {
    padding-left: 10px;
    svg {
      path {
        fill: $text-color;
        transition: 0.5s ease all;
      }
    }
  }
  .btn_create_tree_mob {
    background-color: transparent;
    padding: 0;
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border-color: $dashboard-menu-active;
    svg {
      path {
        fill: $dashboard-add-btn;
        transition: 0.5s ease all;
      }
    }
  }
}

@keyframes navbarAnim {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.logoutSection {
  height: 200px;
  display: flex;
  justify-content: center;
  color: $text-color;
  flex-direction: column;
  .title {
    text-align: center;
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: 500;
  }
  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  li {
    list-style: none;
    background-color: #1d5eff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  li:nth-child(2) {
    background: black;
  }
}
@include max(1279.98) {
  :global(body) {
    padding-top: 52px;
  }
  .loggedin_header {
    padding: 8px 0;
  }
}

.logoutsection_btn_wrap {
  button {
    max-width: 100px;
    min-width: 74px;
    margin: 0 10px !important;
    display: inline-block !important;
    &:global(.btn-light) {
      background-color: transparent;
      border-color: var(--btnhover2);
      color: $text-color;
      &:hover {
        background-color: var(--btnhover2);
        border-color: var(--btnhover2);
      }
    }
  }
}
:root {
  --btnhover2: #e4e4e4;
}
:global(body.dark) {
  --btnhover2: #5b5b68;
}

:global(.dark) {
  .loggedin_header_logo {
    &:first-of-type {
      display: none;
    }
    &:last-of-type {
      display: block;
    }
  }
}
:global(.logout-modal .modal-dialog) {
  max-width: 400px;
}
:global(.logout-modal .modal-body .btn) {
  width: 100%;
  max-width: 135px;
}



.page_loader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: block;
  background-color: white;
  
}

@keyframes bodyfadein{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes bellShake {
  0% {
    transform: rotate(0deg);
  }
  7.69% {
    transform: rotate(10deg);
  }
  15.38% {
    transform: rotate(0deg);
  }
  23.07% {
    transform: rotate(-10deg);
  }
  30.76% {
    transform: rotate(0deg);
  }
  38.45% {
    transform: rotate(10deg);
  }
  46.14% {
    transform: rotate(0deg);
  }
  52.83% {
    transform: rotate(-10deg);
  }
  61.59% {
    transform: rotate(0deg);
  }
  69.21% {
    transform: rotate(0deg);
  }
  76.9% {
    transform: rotate(0deg);
  }
  84.59% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}