@import "../../../styles/uitilities.scss";


.full_view_slider_img_wrap{
    height: auto;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.gallery_image {
    &_tray {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 71px 38px 32px 38px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      background-image: linear-gradient(rgba(#000000, 0), rgba(#000000, 1));
      transform: translateY(100%);
      opacity: 0;
      transition: 0.5s ease all;
      pointer-events: none;
      &:hover {
        opacity: 1;
        transform: translateY(0);
      }
      &_author {
        font-size: size(28);
        font-weight: $font-medium;
        // line-height: 1.8;
        margin-bottom: 6px;
        color: #fff;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
      &_post_date {
        font-size: size(16);
        font-weight: $font-regular;
        // line-height: 1;
        margin-bottom: 15px;
        color: #fff;
      }
      .tray_actions {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 173px;
        justify-content: space-between;
        margin-bottom: 15px;
        &_btn {
          border: none;
          background: #fff;
          border-radius: 50%;
          width: 38px;
          height: 38px;
          margin: 0 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: all;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          svg {
            transition: 0.5s ease all;
          }
          &:hover {
            svg {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }


.gallery_image_tray{
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 71px 32px 23px 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0), black);
    transform: translateY(100%);
    opacity: 0;
    transition: 0.5s ease all;
    pointer-events: none;
    .tray_actions_btn_liked{
        svg{
            path{
                &:nth-child(1){
                    fill: rgb(255, 39, 39);
                }
            }
        }
    }
    .tray_actions_btn_no_like{
        svg{
            path{
                &:nth-child(1){
                    fill: none;
                }
                &:nth-child(2){
                    fill: #000000;
                }
            }
        }
    }
  }

  .gallery_image_wrap {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    cursor: pointer;
    height: auto;
    max-height: 100%;
    margin-bottom: 0;
    max-width: 1000px;
    img{
        max-height: calc(100vh - 215px);
        height: auto;
        max-width: 100%;
    }
  }
  .gallery_image_cont {
    position: relative;
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    img {
      width: 100%;
      height: 100%;
      transition: 0.1s ease-in;
      
    }
    
    .delete_img {
      position: absolute;
      right: -2px;
      top: -12px;
      width: 30px;
      height: 30px;
      transform: rotate(45deg);
      border-radius: 50%;
      background-color: $rearrange-close-bg;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $black;
      font-size: 35px;
      font-weight: 400;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        position: relative;
        top: -4px;
      }
    }
    &_edit {
      // height: 200px;
      img {
        object-fit: cover;
        cursor: move;
      }
    }
  }

.gallery_image_wrap:hover {
    .gallery_image_tray {
        transform: translateY(0);
        opacity: 1;
    }
}




