@import '../../../styles/uitilities.scss';

.popupWrapper{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.74);
    z-index: 999;
    opacity: 0;
    transition: 0.3s ease-in-out opacity;
    @include max(1439.98) {
        padding-left: 250px;
    }
    @include max(1279.98) {
        padding-left: 0;
    }
    @include max(991.98) {
        background-color: $dashboard-bg;
    }
    @include max(575.98) {
        height: calc(100vh - 53px);
    }
}
.popupWrapper.open{
    opacity: 1;
}


.closeWrapper{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 40px;
    right: 40px;
    font-size: 0px;
    border-radius: 50%;
    z-index: 999;
    overflow: hidden;
    button{
        font-size: 0px;
        border: none;
        padding: 0px;
        margin: 0px;
        width: 30px;
        height: 30px;
        background-color: #3A3A3A;
        cursor: pointer;
        transition: .3s background-color ease-in-out;
        &::after{
            position: absolute;
            width: 16px;
            height: 2px;
            background-color: #fff;
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg); 
            transition: .3s background-color ease-in-out;
        }
        &::before{
            position: absolute;
            width: 16px;
            height: 2px;
            background-color: #fff;
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            transition: .3s background-color ease-in-out; 
        }
        &:hover{
            background-color: #fff;
            &::before,
            &::after{
                background-color: #3A3A3A;
            }
        }
    }
    
}


.listWrapper{
    width: auto;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include max(991.98) {
        flex-direction: column;
        justify-content: space-between;
        padding: 100px 0 75px;
        overflow: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .listTop {
        position: relative;
    }
    .mobileHead {
        text-align: center;
        &_title {
            font-size: size(22);
            font-weight: $font-bold;
            color: $text-color-alt;
            margin-bottom: 10px;
        }
        &_description {
            font-size: size(16);
            font-weight: $font-regular;
            color: $text-color-alt;
            margin-bottom: 25px;
        }
    }
    .listMain{
        width: auto;
        display: flex;
        position: relative;
        &.parent_active {
            position: unset;
        }
    }
    .listInner{
        width: auto;
        display: flex;
        flex-wrap: wrap;
        position: relative;

        .nodeWrapper{
            width: 33.333%;
            position: relative;      
            padding: 0px 20px;
            @include max(991.98) {
                width: 50%;
                padding: 0 11px;
            }   
        }
    }
}


.nodeGroup{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
    .nodeList{
        width: 100%;
        position: relative;
        list-style: none;
    }
}


.nodeItem{
    width: 100%;
    max-width: 100%;
    position: relative;
    max-width: 202px;
    padding: 5px;
    background-color: $card-bg;
    border-radius: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 27px;
    border: none;
    @include max(991.98) {
        max-width: 100%;
        box-shadow: 0 3px 6px rgba(#000, 0.1);
    }
    &:last-child{
        margin-bottom: 0;
    }
    .nodeImage{
        width: 50px;
        display: flex;
        .nodeImageContainer{
            width: 100%;
            position: relative;
            padding-bottom: 100%;
            border-radius: 50%;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
    .nodeName{
        width: auto;
        position: relative;
        font-size: 16px;
        line-height: 1.5;
        color: $text-color-alt;
        text-decoration: none;
        font-weight: 500;
        padding: 0px 15px;
        @include max(991.98) {
            font-weight: $font-regular;
        }
    }
}

.continue_btn_wrap {
    max-width: 439px;
    width: 100%;
    padding: 0 16px;
    padding-top: 50px;
    :global(.btn) {
        max-width: 100%;
        width: 100%;
        background-color: #085CFD;
    }
}


.nodeMain{
    width: 33.33%;
    position: relative;
    @include max(991.98) {
        display: none;
    }
}


.nodeMainInner{
    width: 100%;
    position: relative;
    max-width: 164px;
    position: relative;
    background-color: $card-bg;
    border-radius: 10px;
    border: 3px solid #23CC2B;
    padding: 13px 0px;
    overflow: hidden;
    margin: 0px auto;
    height: 100%;
    .nodeImage{
        width:138px;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        .nodeImageContainer{
            width: 100%;
            padding-bottom: 100%;
            position: relative;
            border-radius: 50%;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    .nodeContent{
        width: 100%;
        max-width: 138px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        .title{
            text-align: center;
            font-size: 14px;
            line-height: 1.5;
            color: $text-color-alt;
            font-weight: 600;
            margin: 0px;
            margin-bottom: 3px;
            text-align: center;
        }
        .para{
            font-size: 12px;
            line-height: 1.5;
            color: $input-label;
            font-weight: 400;
            margin: 0px;
            margin-bottom: 0px;
            text-align: center;
        }
    }
}




.extraNode{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    @include max(991.98) {
        width: 50%;
        padding: 0 11px;
    }
    .nodeItem{
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        @include max(991.98) {
            width: 100%;
            margin-left: 0;
        }
    }
}

.aneesh{
    position: relative;
}