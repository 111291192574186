@import '../../../styles/uitilities.scss';


.backtoPage{
   cursor: pointer;
   display: flex;
   align-items: center;

   p{
    font-size: size(17);
    font-weight: $font-regular;
    margin: 0;
    margin-left: 10px;
    color: $text-color-alt;
   }
   svg {
    path {
      fill: $text-color-alt;
    }
   }

  
}

.albumDetails{
  + :global(.masonry-wrap) {
    margin-top: 38px;
    @include max(575.98) {
      margin-top: 20px;
    }
  }
   
     .info {
        h2 {
          font-size: size(28);
          font-weight: $font-bold;
          padding-top: 13px;
          margin-bottom: 13px;
          color: $text-color-alt;
          @include max(575.98) {
            font-size: size(22);
          }
        }
        p {
          font-size: size(18);
          font-weight: $font-regular;
          line-height: 28px;
          margin-bottom: 0;
          color: $text-color-alt;
          @include max(575.98) {
            font-size: size(14);
            line-height: 20px;
          }
        }
      }
    }
    
.album_gallery {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    :global(.my-masonry-grid_column) {
      flex: 0 0 20%;
      max-width: 20%;
      width: 100% !important;
      @include max(1599.98) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      @include max(991.98) {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
      }
      @include max(575.98) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include max(424.98) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    :global(.img-loading) {
      border-radius: 0;
    }
    figure {
      border-radius: 0 !important;
      // overflow: inherit !important;
      img {
        border-radius: 0 !important;
      }
    }
  }
  