@import "../../styles/uitilities.scss";

.left_filtered_category {
    // overflow-x: auto;
    // overflow-y: hidden;
    scrollbar-width: none;
    position: relative;
  
    &::-webkit-scrollbar {
      display: none;
    }
    &_list {
      display: flex;
      list-style: none;
      padding: 20px 0 18px 0;
      margin: 0;
      position: relative;
      @include max(1199.98) {
        display: flex;
      }
      div {
        text-transform: capitalize;
        display: inline-block;
        padding: 0 22px;
        margin: 0 8px;
        @include max(1439.98) {
          padding: 0 12px;
        }
        &::before {
          content: "";
          width: 0;
          height: 2px;
          background: $blue;
          position: absolute;
          bottom: -25px;
          left: 0;
          transition: 0.3s ease all;
          @include max(1439.98) {
            bottom: -21px;
          }
          @include max(1199.98) {
            bottom: -16px;
          }
        }
        a {
          font-weight: $font-regular;
          line-height: 1;
          color: $dashboard-menu-item;
        //   opacity: 0.61;
          transition: 0.3s ease all;
          position: relative;

          @include min(1200){
          font-size: size(18);
          }
          @include min-max(576,1199.98){
          font-size: size(16);
          }
          @include max(575.98){
          font-size: size(14);
          }
          &:before{
            content: attr(data-name);
            position: absolute;
            font-weight: 500;
            white-space: nowrap;
            color: transparent;
          }


          @include max(767.98) {
            font-size: size(16);
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
  
        &:hover {
          a {
            color: $dashboard-btn-gray2-hover;
          }
        }
      }
      &_active{
        a{
          color: transparent!important;
          transition: none!important;
          &:before{
            color: $modal-link!important;
          }
        }
      }
    }
    
  }
 

  .floater {
    background-color: $modal-link;
    position: absolute;
    height: 4px;
    bottom: 0px;
    border-radius: 2px;
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    margin-left: 0px!important;

  }

.user_profile{
    @include min(768){
      padding: 44px 50px 44px;
    }
    
    &_row{
        display: flex;
        width: 100%;
    }
    &_profimg{
        padding: 0 17px 20px;
        width: 100%;
        max-width: 176px;
        flex: 0 0 176px;
        figure{
            margin-bottom: 0px;
            position: relative;
            width: 100%;
            max-width: 142px;
            overflow: hidden;
            border-radius: 100%;
            &:before{
                content: "";
                position: relative;
                display: block;
                width: 100%;
                padding-bottom: 100%;
            }
            img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 100%;
            }
        }
    }
    &_details{
        padding: 0 17px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        &_row{
            border-bottom: 1px solid $dashboard-item-border;
            display: flex;
            align-items: flex-end;
        }
        &_name{
            font-weight: 500;
            margin-bottom: 8px;
            color: $text-color;
            @include min(1200){
              font-size: size(34);
            }
            @include min-max(992,1199.98){
              font-size: size(31);
            }
            @include min-max(768,991.98){
              font-size: size(27);
            }
            @include min-max(576,767.98){
              font-size: size(25);
            }
            @include max(575.98){
              font-size: size(22);
            }
        }
        &_main{
            padding-right: 40px;
        }
        // &_links{

        // }
        &_btn{
            background-color: transparent;
            padding: 8px 22px;
            border: 1px solid $text-color;
            color: $text-color;
            border-radius: 4px;
            font-size: size(17);
            font-weight: 500;
            margin: 12px 9px;
            @include max(1439.98){
              &:nth-child(1){
                margin-left: 0px;
              }
            }

            @include min(1200){
            font-size: size(17);
            }
            @include min-max(576,1199.98){
            font-size: size(15);
            }
            @include max(575.98){
            font-size: size(13);
            }
        }
    }
    &_header{
        background-color: $dashboard-header-bg;
        padding: 22px 5px 0px;
        box-shadow: 0 3px 6px rgba(#071454,7%);
        @include min(768){
          border-radius: 10px;
        }
        :global(.list-group){
            flex-direction: row;
            :global(.list-group-item){
                background-color: transparent;
                display: inline-block;
                border: none;
                padding-left: 0px;
                padding-right: 33px;
                color: $text-color;
                @include min(1200){
                  font-size: size(16);
                }
                @include min-max(576,1199.98){
                  font-size: size(14);
                }
                @include max(575.98){
                  font-size: size(13);
                }
            }
        }
    }
}

:global(body.dark){
    .user_profile_details_row{
        border-color: #767C8D;
    }
}
