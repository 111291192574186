@import '../../styles/uitilities.scss';

.home_choose_plans {
    background: $dark-bg-alt-3;
    padding: 88px 0 109px;
    overflow: hidden;
    position: relative;
    transition: 0.5s ease all;
    @include max(991.98) {
        padding: 70px 0;
    }
    @include max(767.98) {
        padding: 53px 0 40px;
    }
    :global(.container) {
        @include max(575.98) {
            padding: 0;
        }
    }
    &_get_started {
      background: transparent;
      padding: 0 0 50px 0;
      @include max(767.98) {
          padding: 0 0 37px 0;
      }
      .anim_elements {
          display: none !important;
      }
    }
    &_title {
      margin-bottom: 68px;
      @include max(991.98) {
          margin-bottom: 50px;
      }
      @include max(767.98) {
          margin-bottom: 42px;
      }
      @include max(575.98) {
          padding: 0 15px;
      }
    }
    &_cards {
        display: flex;
        justify-content: center;
        max-width: 1196px;
        margin: 0 auto;
        @include max(1199.98) {
            flex-wrap: wrap;
        }
        @include min-max(576, 1199.98) {
            margin: 0 -15px;
        }
    }
    &_card_wrap {
        padding: 0 17px;
        z-index: 3;
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        @include max(1199.98) {
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: 30px;
        }
        @include max(767.98) {
            flex: 0 0 100%;
            max-width: 100%;
        }
        &:nth-child(2) {
            .home_choose_plans_card {
                @include min(1200) {
                    height: 109%;
                    margin-top: -23px;
                    padding-bottom: 54px;
                }
                .plan_duration {
                    color: $orange-alt;
                }
            }
        }
        &:nth-child(3n + 2) {
            .home_choose_plans_card {
                .plan_duration {
                    color: $green-alt;
                }
            }
        }
        &:nth-child(3n) {
            .home_choose_plans_card {
                .plan_duration {
                    color: $blue-alt;
                    filter: brightness(1.5);
                }
            }
        }
        &:global(.data-loading) {
            &:nth-child(3n) {
                .home_choose_plans_card {
                    .plan_duration {
                        filter: brightness(1);
                    }
                }
            }
        }
    }
    &_card {
        background: $card-bg;
        border-radius: 13px;
        padding: 50px;
        padding-bottom: 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        box-shadow: 0 10px 10px $card-box-shadow;
        // box-shadow: 0 17px 22px $card-box-shadow;
        transition: 0.5s ease all;
        @include min-max(768, 991.98) {
            padding: 35px;
            padding-bottom: 35px;
        }
        @include max(374.98) {
            padding: 40px 25px;
        }
        .card_top {
            margin-bottom: 30px;
        }
        &.plan_recommended {
            @include min(1200) {
                height: 109%;
                margin-top: -23px;
                padding-bottom: 54px;
            }
            .plan_duration {
                margin-bottom: 26px;
                display: inline-block;
            }
            .plan_amount {
                margin-bottom: 24px;
            }
            .plan_description {
                margin-bottom: 38px;
            }
            .recommended_label {
                display: inline-block;
                color: $label-color;
                padding: 5px 7px;
                border-radius: 4px;
                background: $label-bg;
                margin-left: 26px;
                font-size: size(14);
                font-weight: $font-regular;
                transition: 0.5s ease all;
                @include max(767.98) {
                    font-size: size(12);
                }
            }
        }
        &_button {
            :global(.btn) {
                width: 100%;
            }
        }
    }
    .plan {
        &_duration {
            font-size: size(18);
            font-weight: $font-medium;
            margin-bottom: 21px;
            line-height: 1.4;
            @include max(767.98) {
                font-size: size(16);
            }
            &.plan_monthly {
                color: $orange-alt;
            }
            &.plan_yearly {
                color: $green-alt;
            }
            &.plan_quaterly {
                color: $blue-alt;
            }
        }
        &_amount {
            color: $plan-month;
            font-family: $font-family-inter;
            font-size: size(14);
            font-weight: $font-regular;
            margin-bottom: 14px;
            transition: 0.5s ease all;
            @include max(767.98) {
                font-size: size(14);
                margin-bottom: 14px;
            }
            &_value {
                font-size: size(36);
                font-weight: $font-bold;
                color: $text-color-alt;
                transition: 0.5s ease all;
                @include max(767.98) {
                    font-size: size(32);
                }
            }
        }
        &_description {
            font-size: size(18);
            font-weight: $font-regular;
            line-height: 26px;
            color: $text-color;
            margin-bottom: 33px;
            transition: 0.5s ease all;
            @include max(767.98) {
                font-size: size(15);
                line-height: 23px;
                margin-bottom: 25px;
            }
        }
        &_features {
            list-style: none;
            padding-left: 27px;
            margin-bottom: 31px;
            @include max(575.98) {
                margin-bottom: 0;
            }
            li {
                font-size: size(16);
                font-weight: $font-regular;
                line-height: 22px;
                color: $text-color-alt;
                position: relative;
                letter-spacing: 0.16px;
                transition: 0.5s ease all;
                @include max(767.98) {
                    font-size: size(15);
                    line-height: 19px;
                }
                &:not(:last-child) {
                    margin-bottom: 13px;
                    @include max(767.98) {
                        margin-bottom: 15px;
                    }
                }
                &::before {
                    content: "";
                    width: 6px;
                    height: 11px;
                    border: 2px solid transparent;
                    border-right: 2px solid $list-bullet-tick;
                    border-bottom: 2px solid $list-bullet-tick;
                    position: absolute;
                    top: 50%;
                    left: -22px;
                    transform: translateY(-50%) rotate(45deg);
                    transition: 0.5s ease all;
                }
            }
        }
    }
    &_slider {
        padding: 0 17px 80px !important;
        margin: 0 -17px;
        @include min-max(576, 767.98) {
            overflow: visible !important;
        }
        @include max(767.98) {
            padding: 20px 17px 80px !important;
            margin: 0 -15px;
        }
        .plan_recommended {
            height: 100%;
            transform: translateY(0);
        }
        .home_choose_plans_card {
            transition: 0.7s ease all;
        }
        :global(.swiper-slide) {
            height: auto;
            &:nth-child(3n + 2) {
                .home_choose_plans_card {
                    .plan_duration {
                        color: $green-alt;
                    }
                }
            }
            &:nth-child(3n) {
                .home_choose_plans_card {
                    .plan_duration {
                        color: $blue-alt;
                    }
                }
            }
            &:nth-child(2) {
                :global(.btn-border-primary) {
                    background-color: $btn-primary;
                    color: #fff;
                    &:hover {
                        background-color: $btn-primary;
                        border-color: $btn-primary !important;
                    }
                }
            }
        }
        :global(.swiper-slide-active) {
            .home_choose_plans_card {
                @include max(767.98) {
                    height: 108%;
                    transform: translateY(-20px) !important;
                    transition: 0.7s ease all;
                }
            }
        }
        :global(.swiper-pagination) {
            bottom: 0 !important;
        }
        :global(.swiper-pagination .swiper-pagination-bullet) {
            background: $pagination-bullet;
            width: 10px;
            height: 10px;
            transition: 0.5s ease all;
        }
        :global(.swiper-pagination .swiper-pagination-bullet-active) {
            background: $pagination-bullet-active;
            transition: 0.5s ease all;
        }
    }
    .anim_elements {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      .anim_elem {
        position: absolute;
        width: max-content;
        height: max-content;
        margin-bottom: 0;
        @include max(991.98) {
            display: none;
        }
        &.circle_green {
            top: 18.3%;
            transform: translateX(calc(-72vw / 2)) scale(0.9);
            @include max(1199.98) {
                top: 14.3%;
            }
        }
        &.donut_yellow {
            bottom: 21.6%;
            transform: translateX(calc(77vw / 2));
            @include max(1599.98) {
                transform: translateX(calc(88vw / 2));
            }
        }
      }
    }
}

// shimmer effects
:global(.data-loading) {
    .home_choose_plans {
        &_card {
            width: 100%;
            &_wrap {
                width: 100%;
            }
            .shimmer-styles {
                border-radius: 5px;
                width: 100%;
                background: $shimmer-bg;
                background-image: $shimmer-gradient;
                background-repeat: no-repeat;
                background-size: 800px 104px; 
                display: inline-block;
                position: relative; 
                
                -webkit-animation-duration: 1s;
                -webkit-animation-fill-mode: forwards; 
                -webkit-animation-iteration-count: infinite;
                -webkit-animation-name: placeholderShimmer;
                -webkit-animation-timing-function: linear;
            }
            .plan {
                &_duration {
                    max-width: 120px;
                    height: 20px;
                    @extend .shimmer-styles;
                }
                &_amount {
                    max-width: 200px;
                    height: 30px;
                    display: block;
                    @extend .shimmer-styles;
                }
                &_description {
                    .lines {
                        margin-bottom: 10px;
                        display: block;
                        height: 15px;
                        @extend .shimmer-styles;
                    }
                }
                &_features {
                    li {
                        height: 15px;
                        @extend .shimmer-styles;
                    }
                }
            }
            &_button {
                height: 50px;
                width: 100%;
                @extend .shimmer-styles;
            }
        }
    }
}

@keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    
    100% {
      background-position: 468px 0; 
    }
  }
