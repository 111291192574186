@import '../../styles/uitilities.scss';

.invitations{
    color: $text-color;
    @include min(768) {
        position: fixed;
        width: calc(100vw - 250px);
    }
    @include min(1440) {
        position: fixed;
        width: calc(100vw - 310px);
    }
    @include min(768) {
        padding: 50px;
        // min-height: calc(100vh - 83px);
    }
    &_wrap {
        overflow: auto;
        @include min(768) {
            height: calc(100% + 16px);
            padding-right: 50px;
            position: relative;
            &::after {
                content: "";
                width: 100%;
                height: 80px;
                position: fixed;
                bottom: 0;
                left: 0;
                background-image: linear-gradient(to top, #ffffff, #ffffff00);
            }
        }
        &::-webkit-scrollbar {
            width: 6px;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          background: rgba(#071454, 0.07);
        }
        &::-webkit-scrollbar-thumb {
            background: #D2D4DA;
            border-radius: 10px;
        }
    }
}
.invitations_container{
    background-color: $dashboard-header-bg;
    @include min(768) {
        height: calc(100vh - 133px);
    }
}
.tab_item_title{
    font-size: size(30);
    font-weight: 500;
    @include min(992){
        margin-bottom: 10px;
    }
}

@include min(768){
    .invitations_container{
        border-radius: 12px;
    }
}

@include max(767.98){
    .invitations_container{
        padding: 15px;
        min-height: calc(100vh - 52px);
        .tab_item_title{
            border-bottom: 2px solid $dashboard-item-border;
            font-size: size(18);
            padding: 0px 0 15px 0;
            line-height: 100%;
        }
    }
}
@include min(576){
    .invitations_container{
        padding: 42px 62px 75px;
    }
}
@include max(575.98){
    .invitations_container{
        padding: 15px;
    }
}