.searchprofile {
  display: flex;
    height: 186px;
    width: 1429px;
    background-color: white;
    margin: 20px;

  .searchprofile_figure {
    width: 142px;
    height: 142px;
    margin: 24px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
  .searchprofile_details {
    display: flex;
    border-bottom: inset;
    gap: 30px;
    width: 100%;
    width: 1280px;
    padding-top: 35px;
    align-items: center;
    font-size: small;

    .searchprofile_address {
      padding: 5px  ;
      margin-top: 10px;
      ul{
        display: flex;
        li{
          padding-right: 35px;
          list-style-type: none;
        }
      }
    }
    .Button {
      button {
        background-color: white;
        width: 140px;
        color: black;
        border-radius: 5px;
        border-color: black;
        padding: 5px;
      }
    }
  }
}
