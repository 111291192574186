.familymembers {
  margin: 20px;
  background-color: white;
  padding: 24px;
  width: 455px;
  ul {
    display: flex;
    list-style-type: none;
    justify-content: space-between;
  }
  .familymembers_scrollcontainer {
    height: 420px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
