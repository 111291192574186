@import "../../../../styles/uitilities.scss";

.drop_container {
  .gallery_image_wrap {
    position: relative;
    overflow: hidden;
    // border-radius: 20px;
    cursor: pointer;
    background-color: $input-border;
    margin-bottom: 20px;
    width: 100%;
    // border-image-source: inherit !important;

    :global(.progress) {
      position: absolute;
      bottom: 23px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 238px;
      width: 100%;
      height: 8px;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 10px;
      padding: 1px;
    }
    :global(.progress-bar) {
      background-color: #23cc2b;
      border-radius: 10px;
    }

    &.image_error {
      img {
        box-shadow: inset 0 0 0 1px $error-message;
        border-radius: 21px;
      }
      :global(.progress-bar) {
        background-color: $error-message;
      }
    }
  }
  .onHover_figure {
    .gallery_image_tray {
      border-radius: 0;
      opacity: 1;
      transform: translateY(1px);
    }
  }

  .uploadError {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    span {
      display: block;
    }
    .errorButton {
      background: grey;
      margin: 0 auto;
      display: inline-block;
      margin-top: 150px;
      border-radius: 3px;
      padding: 4px;
    }
  }

  .shimmer-styles {
    border-radius: 20px;
    width: 100%;
    background: $shimmer-bg-alt;
    background-image: $shimmer-gradient-alt;
    background-repeat: no-repeat;
    background-size: 800px 100%;
    display: inline-block;
    position: relative;

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
  }
  :global(.img-loading) {
    @extend .shimmer-styles;
    // max-width: 286px;
    // width: 100%;
    height: 300px;
    min-height: 300px;
    max-height: 300px;
    // @include max(575.98) {
    //   max-width: 100%;
    // }
    img {
      opacity: 0;
    }
  }
  :global(.img-loaded) {
    @extend .shimmer-styles;
    min-height: 1px !important;
    max-height: 800px !important;
    img {
      // transform: scale(1) !important;
      opacity: 1 !important;
    }
  }

  :global(.img-loading .image_tray) {
    display: none !important;
  }

  .gallery_image_cont {
    position: relative;
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    .gallery_image_wrap {
      // min-height: 300px;
      // max-height: 300px;
      transition: 1s ease min-height, 1s ease max-height;
      overflow: hidden !important;
    }
    img {
      width: 100%;
      height: 100%;
      // transform: scale(1.1);
      // transition: 0.35s ease-in-out transform 0s ease border-radius;
      border-radius: 20px;
      overflow: hidden;
      // opacity: 0;
      // transition: 0.35s ease-in-out transform, 0.35s ease-in-out opacity, 0s ease border-radius;
      // transition: 0.35s ease-in-out transform, 0.35s ease-in-out opacity, 0s ease border-radius;
      transition-delay: 0.25s;
      &:global(.onHover) {
        + .gallery_image_tray {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
    .delete_img {
      position: absolute;
      right: -2px;
      top: -12px;
      width: 30px;
      height: 30px;
      transform: rotate(45deg);
      border-radius: 50%;
      background-color: $rearrange-close-bg;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $black;
      font-size: 35px;
      font-weight: 400;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        position: relative;
        top: -2px;
      }
    }
    &_edit {
      img {
        object-fit: cover;
        cursor: move;
      }
    }
    &.favorite_active {
      .favorite_btn {
        svg {
          path {
            &:first-child {
              fill: red;
            }
            &:last-child {
              fill: red;
            }
          }
        }
      }
    }
  }

  :global(.favorite-active .favorite-btn) {
    svg {
      animation: box-shadow-active 0.5s ease-in-out !important;
      opacity: 1 !important;
    }
  }
  :global(.favorite-btn) {
    svg {
      border-radius: 100px;
      height: 14px;
      width: 14px;
    }
  }

  .gallery_select_tick {
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 5;
    width: 25px;
    height: 25px;
    margin-bottom: 0;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #1d5eff;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    svg {
      width: 13px;
      height: 13px;
      margin-top: 1px;
    }
  }

  :global(.selection) {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    z-index: 10;
    bottom: 15px;
    right: 15px;
    border: 2px solid #bbafaf;
    box-shadow: 0 0 100vmin 100vmin #00000062;
    pointer-events: none;
  }
}

.gallery_image {
  &_tray {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 71px 12px 17px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    background-image: linear-gradient(rgba(#000000, 0), rgba(#000000, 1));
    transform: translateY(100%);
    opacity: 0;
    // transition: 0.35s ease transform;
    pointer-events: none;
    &_author {
      font-size: size(16);
      font-weight: $font-medium;
      line-height: 1;
      margin-bottom: 6px;
      color: #fff;
    }
    &_post_date {
      font-size: size(13);
      font-weight: $font-regular;
      line-height: 1;
      color: #fff;
    }
    .tray_actions {
      display: flex;
      align-items: center;
      &_btn {
        border: none;
        background: #fff;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        margin: 0 3.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: all;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        // svg {
        //   transition: 0.5s ease all;
        // }
        &:hover {
          svg {
            opacity: 0.5;
          }
        }
        &.favorite_btn {
          svg {
            cursor: pointer;
            overflow: visible;
            width: 32px;
            :global(#heart) {
              transform-origin: center;
              animation: animateHeartOut 0.3s linear forwards;
            }
            :global(#main-circ) {
              transform-origin: 29.5px 29.5px;
            }
          }
        }
      }

      .favorite_btn {
        svg {
          path {
            fill: $black;
            &:nth-of-type(2) {
              fill: $white;
              transform: scale(0.87) !important;
              transform-origin: center;
            }
            &:last-child {
              fill: $black;
            }
          }
        }
      }
    }
  }
}

@keyframes box-shadow-active {
  0% {
    box-shadow: 0 0 0px 0px white;
  }
  25% {
    box-shadow: 0 0 0px 15px white;
  }
  100% {
    box-shadow: 0 0 0px 0px white;
  }
}

.tray_heart_btn {
  svg {
    path {
      fill: currentColor;
      display: none;
      &:nth-child(2) {
        display: block;
      }
    }
  }
  &.heart_checked {
    svg {
      path {
        display: block;
      }
    }
    color: red;
  }
}

.tray_heart_btn,
.tray_actions_trash_btn,
.tray_actions_dwd_btn {
  overflow: hidden;
  position: relative;
  svg {
    path {
      fill: currentColor;
    }
  }
}
.tray_actions_dwd_btn {
  &:hover {
    color: $green-alt !important;
    svg {
      opacity: 1 !important;
      path {
        color: currentColor !important;
      }
    }
  }
}
.tray_heart_btn {
  &:hover {
    color: $orange-red-alt !important;
    svg {
      opacity: 1 !important;
      path {
        color: currentColor !important;
      }
    }
  }
}
.tray_actions_trash_btn {
  &:hover {
    color: $orange-red-alt !important;
    svg {
      opacity: 1 !important;
      path {
        color: $orange-red-alt !important;
      }
    }
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.placeholder_image_active {
  background-color: $gallery-img-bg;
}

// favorite toggle animation
.favorite_active .favorite_btn svg {
  path {
    stroke: none !important;
  }
  :global(#heart) {
    transform-origin: center;
    transform: scale(0.2);
    fill: #e2264d;
    animation: animateHeart 0.3s linear forwards 0.25s;
  }
  :global(#main-circ) {
    pointer-events: none;
    transition: all 2s;
    animation: animateCircle 0.3s linear forwards;
    opacity: 1;
  }
  :global(#grp1) {
    opacity: 1;
    transition: 0.1s all 0.3s;
    :global(#oval1) {
      transform: scale(0) translate(0, -30px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }
    :global(#oval2) {
      transform: scale(0) translate(10px, -50px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }
  :global(#grp2) {
    opacity: 1;
    transition: 0.1s all 0.3s;
    :global(#oval1) {
      transform: scale(0) translate(30px, -15px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }
    :global(#oval2) {
      transform: scale(0) translate(60px, -15px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }
  :global(#grp3) {
    opacity: 1;
    transition: 0.1s all 0.3s;
    :global(#oval1) {
      transform: scale(0) translate(30px, 0px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }
    :global(#oval2) {
      transform: scale(0) translate(60px, 10px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }
  :global(#grp4) {
    opacity: 1;
    transition: 0.1s all 0.3s;
    :global(#oval1) {
      transform: scale(0) translate(30px, 15px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }
    :global(#oval2) {
      transform: scale(0) translate(40px, 50px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }
  :global(#grp5) {
    opacity: 1;
    transition: 0.1s all 0.3s;
    :global(#oval1) {
      transform: scale(0) translate(-10px, 20px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }
    :global(#oval2) {
      transform: scale(0) translate(-60px, 30px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }
  :global(#grp6) {
    opacity: 1;
    transition: 0.1s all 0.3s;
    :global(#oval1) {
      transform: scale(0) translate(-30px, 0px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }
    :global(#oval2) {
      transform: scale(0) translate(-60px, -5px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }
  :global(#grp7) {
    opacity: 1;
    transition: 0.1s all 0.3s;
    :global(#oval1) {
      transform: scale(0) translate(-30px, -15px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }
    :global(#oval2) {
      transform: scale(0) translate(-55px, -30px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }
  :global(#grp2) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }
  :global(#grp3) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }
  :global(#grp4) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }
  :global(#grp5) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }
  :global(#grp6) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }
  :global(#grp7) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }
}

@keyframes animateCircle {
  40% {
    transform: scale(10);
    opacity: 1;
    fill: #dd4688;
  }
  55% {
    transform: scale(11);
    opacity: 1;
    fill: #d46abf;
  }
  65% {
    transform: scale(12);
    opacity: 1;
    fill: #cc8ef5;
  }
  75% {
    transform: scale(13);
    opacity: 1;
    fill: transparent;
    stroke: #cc8ef5;
    stroke-width: 0.5;
  }
  85% {
    transform: scale(17);
    opacity: 1;
    fill: transparent;
    stroke: #cc8ef5;
    stroke-width: 0.2;
  }
  95% {
    transform: scale(18);
    opacity: 1;
    fill: transparent;
    stroke: #cc8ef5;
    stroke-width: 0.1;
  }
  100% {
    transform: scale(19);
    opacity: 1;
    fill: transparent;
    stroke: #cc8ef5;
    stroke-width: 0;
  }
}

@keyframes animateHeart {
  0% {
    transform: scale(0.2);
  }
  40% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes animateHeartOut {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

.frameFit {
  width: inherit;
  height: inherit;
  border-width: 20px;
  border-radius: 12px;
  border-style: inset;
  display: block;
  margin: 0 auto;
  display: grid;
  place-items: center;
  transition: all 0.4s;
  border-image-slice: 13%;
  border-image-repeat: round;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

:global(.img-loaded img:first-child) {
  // transform: scale(1.01) !important;
}
