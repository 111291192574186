@import "../../../styles/uitilities.scss";

.left_filtered_category {
    // overflow-x: auto;
    // overflow-y: hidden;
    scrollbar-width: none;
    position: relative;
  
    &::-webkit-scrollbar {
      display: none;
    }
    &_list_wrap{
      border-bottom: 1px solid $dashboard-item-border;
      width: 100%;
      margin-bottom: 24px;
    }
    &_list {
      display: flex;
      list-style: none;
      padding: 6px 0;
      margin: 0;
      position: relative;
      justify-content: space-between;
      max-width: 500px;
      
      @include max(1199.98) {
        display: flex;
      }
      div {
        text-transform: capitalize;
        display: inline-block;
        padding: 0 7px;
        @include max(1439.98) {
          padding: 0 5px;
        }
        &::before {
          content: "";
          width: 0;
          height: 2px;
          background: $blue;
          position: absolute;
          bottom: -25px;
          left: 0;
          transition: 0.3s ease all;
          @include max(1439.98) {
            bottom: -21px;
          }
          @include max(1199.98) {
            bottom: -16px;
          }
        }
        a {
          font-size: size(13);
          font-weight: $font-regular;
          line-height: 1;
          color: $dashboard-menu-item;
        //   opacity: 0.61;
          transition: 0.3s ease all;
          position: relative;
          &:before{
            content: attr(data-name);
            position: absolute;
            font-weight: 500;
            white-space: nowrap;
            top: 1px;
            color: transparent;
          }
          @include max(767.98) {
            font-size: size(13);
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
  
        &:hover {
          a {
            color: $dashboard-btn-gray2-hover;
          }
        }
      }
      &_active{
        a{
          color: transparent!important;
          transition: none!important;
          &:before{
            color: $modal-link!important;
          }
        }
      }
    }

    &_members{
        &_relation{
            font-size: size(15);
            color: $text-color;
            margin-bottom: 5px;
            margin-top: 5px;
            font-weight: 500;
            padding-top: 0px;
        }
        &_row{
            margin-left: -10px;
            margin-right: -10px;
            display: flex;
            flex-wrap: wrap;
        }
    }
    
  }
 

  .floater {
    background-color: $modal-link;
    position: absolute;
    height: 4px;
    bottom: -2px;
    border-radius: 2px;
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    margin-left: 0px!important;

  }


  :global(body.dark){
    .user_profile_details_list{
        border-color: #767C8D;
    }
}


.User_profile_overview{
    &_right_side{
        width: 100%;
    }
    &_sec{
        width: 100%;
        background-color: $dashboard-header-bg;
        padding: 30px 28px;
        @include min(768){
          border-radius: 10px;
          margin: 25px 0;
        }
        @include max(767.98){
          margin: 10px 0;
        }
    }
    &_title{
        font-size: size(24);
        font-weight: 500;
        color: $text-color;
        margin-bottom: 20px;
    }
    
}

