@import '../../styles/uitilities.scss';

.home_memories {
    padding: 100px 0 88px;
    position: relative;
    @include max(991.98) {
        padding: 70px 0;
    }
    @include max(767.98) {
        padding: 68px 0 55px;
    }
    &_title {
        margin-bottom: 101px;
        @include max(991.98) {
            margin-bottom: 50px;
        }
    }
    &_gallery {
        display: flex;
        align-items: center;
        animation: sliderAnim 40s linear infinite forwards;
        width: var(--sliderWidth);
        &:hover {
            -webkit-animation-play-state: paused;
            -moz-animation-play-state: paused;
            -o-animation-play-state: paused;
            animation-play-state: paused;
        }
        &_wrap {
            overflow: hidden;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            /* smartphones, touchscreens */
            // @media (hover: none) and (pointer: coarse) {
            //     overflow: hidden;
            // }
        }
    }
    &_column {
        flex: 0 0 235px;
        max-width: 235px;
        margin-right: 18px;
        &:nth-child(6n+1) {
            flex: 0 0 370px;
            max-width: 370px;
            @include max(767.98) {
                flex: 0 0 296px;
                max-width: 296px;
            }
            @include max(374.98) {
                flex: 0 0 246.6667px;
                max-width: 246.6667px;
            }
        }
        &:nth-child(3n+4) {
            flex: 0 0 335px;
            max-width: 335px;
            @include max(767.98) {
                flex: 0 0 268px;
                max-width: 268px;
            }
            @include max(374.98) {
                flex: 0 0 223.2222px;
                max-width: 223.2222px;
            }
        }
        &:nth-child(12n+1) {
            .column_bottom {
                // margin-left: auto;
            }
        }
    }
    .memory_group {
        .column_top {
            display: flex;
            margin-left: -9px;
            margin-right: -9px;
            @include max(767.98) {
                margin-left: -7px;
                margin-right: -7px;
            }
            @include max(374.98) {
                margin-left: -5px;
                margin-right: -5px;
            }
            &_left {
                flex: 0 0 50%;
                max-width: 50%;
                padding-left: 9px;
                padding-right: 9px;
                @include max(767.98) {
                    padding-left: 7px;
                    padding-right: 7px;
                }
                @include max(374.98) {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
            &_right {
                flex: 0 0 50%;
                max-width: 50%;
                display: flex;
                align-items: flex-end;
                padding-left: 9px;
                padding-right: 9px;
                @include max(767.98) {
                    padding-left: 7px;
                    padding-right: 7px;
                }
                @include max(374.98) {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
        .column_bottom {
            max-width: 235px;
        }
    }
    &_image {
        position: relative;
        overflow: hidden;
        border-radius: 13px;
        margin-bottom: 18px;
        width: 100%;
        transform: translateZ(0);
        background: $shimmer-bg;
        @include max(767.98) {
            margin-bottom: 14px;
        }
        @include max(374.98) {
            margin-bottom: 10px;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
            transition: 1s ease all;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
          
        }
        &::after {
            content: "";
            display: block;
            height: 0;
            width: 100%;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
        &.aspect_1 {
            &::after {
                padding-bottom: calc(116 / 176 * 100%);
            }
        }
        &.aspect_2 {
            &::after {
                padding-bottom: calc(142 / 176 * 100%);
            }
        }
        &.aspect_3 {
            &::after {
                padding-bottom: calc(228 / 176 * 100%);
            }
        }
        &.aspect_4 {
            &::after {
                padding-bottom: calc(157 / 235 * 100%);
            }
        }
        &.aspect_6 {
            &::after {
                padding-bottom: calc(305 / 235 * 100%);
            }
        }
        &.aspect_7 {
            &::after {
                padding-bottom: calc(190 / 235 * 100%);
            }
        }
        &.aspect_8 {
            &::after {
                padding-bottom: calc(156.72 / 235 * 100%);
            }
        }
        &.aspect_9 {
            &::after {
                padding-bottom: calc(555 / 335 * 100%);
            }
        }
    }
    .anim_elements {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      .anim_elem {
        position: absolute;
        width: max-content;
        height: max-content;
        margin-bottom: 0;
        z-index: -1;
        @include max(991.98) {
            display: none;
        }
        &.circle_blue {
            top: 27%;
            transform: translateX(calc(-49.5vw / 2));
        }
        &.circle_green {
            top: 25%;
            transform: translateX(calc(50vw / 2));
        }
        &.donut_yellow {
            bottom: 2%;
            transform: translateX(calc(-26.5vw / 2));
        }
      }
    }
}



@keyframes sliderAnim {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc( var(--sliderWidth) / -2 ));
    }
}



// @keyframes sliderAnim2 {
//     0% {
//         transform: translateX(-3.5555%);
//     }
//     100% {
//         transform: translateX(-36.6666%);
//     }
// }