@import '../../styles/uitilities.scss';

.darkmode_toggler {
    font-size: size(18);
    font-weight: $font-medium;
    color: $dark-toggle;
    position: fixed;
    right: -15px;
    height: 40px;
    border-radius: 5px 5px 0 0;
    top: 400px;
    background-color: $dark-toggle-bg;
    cursor: pointer;
    z-index: 9;
    border: none;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5), 0 0 15px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    min-width: 129px;
    height: 40px;
    transition: 0.5s ease background-color, 0.5s ease color;
    @include min(1279.98) {
      transform: translate(26%, -50%) rotate(270deg);
    }
    @include max(991.98) {
      top: min(50vh, 300px);
      right: 0;
      transform: translateY(-50%);
      width: 45px;
      height: 45px;
      min-width: inherit;
      padding: 0;
      border-radius: 5px 0 0 5px;
    }
    @include max(767.98) {
      top: min(50vh, 250px);
    }
    @include max(575.98) {
      top: 50%;
    }
    svg {
      transform: rotate(90deg);
      position: relative;
      top: -2px;
      transition: 0.3s ease all;
      @include max(575.98) {
        top: 0;
      }
    }
    span {
      padding-left: 12px;
      font-size: size(20);
      font-weight: $font-light;
      @include max(991.98) {
        display: none;
      }
    }
    &:hover {
      svg {
        transform: rotate(45deg);
        @include max(991.98) {
          transform: rotate(120deg);
        }
      }
    }
    .dark_mode {
      span {
        padding-left: 0;
        padding-right: 12px;
      }
      svg {
        path {
          fill: #fff;
        }
      }
    }
    &:global(.horizontal .theme-mode) {
      display: flex !important;
      flex-direction: row-reverse;
    }
}