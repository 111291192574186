@import '../../styles/uitilities.scss';

.home_external_links {
    padding: 51px 0 60px;
    background: $dark-bg-alt-2;
    transition: 0.5s ease all;
    @include max(767.98) {
        padding: 33px 0;
    }
    :global(.row) {
        justify-content: center;
    }
    :global(.col) {
        @include max(991.98) {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
            margin-bottom: 20px;
            &:nth-last-child(1),
            &:nth-last-child(2) {
                margin-bottom: 0;
            }
        }
        @include max(575.98) {
            flex: 0 0 50%;
            max-width: 50%;
            &:nth-last-child(2) {
                margin-bottom: 20px;
            }
        }
        @include max(374.98) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    &_slider {
        padding-bottom: 40px !important;
        :global(.swiper-pagination) {
            bottom: 0 !important;
        }
        :global(.swiper-pagination .swiper-pagination-bullet) {
            background: $pagination-bullet;
            width: 10px;
            height: 10px;
            opacity: 0.6;
        }
        :global(.swiper-pagination .swiper-pagination-bullet-active) {
            background: $pagination-bullet-active;
            opacity: 1;
        }
    }
    &_card {
        position: relative;
        border-radius: 13px;
        overflow: hidden;
        box-shadow: 0 3px 6px rgba($black, 0.16);
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            &:hover {
                ~ .home_external_links_card_link {
                    .link_text {
                        display: block;
                    }
                }
            }
        }
        &_image {
            position: relative;
            overflow: hidden;
            margin-bottom: 0;
            background: $shimmer-bg;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
                max-width: 100%;
                max-height: 100%;
            }
            &::after {
                content: "";
                display: block;
                height: 0;
                width: 100%;
                padding-bottom: calc(242 / 252 * 100%);
            }
        }
        &_link {
            background: $white;
            padding: 0 5px;
            height: 23px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: max-content;
            position: absolute;
            top: 12px;
            right: 12px;
            .link_text {
                font-size: size(10);
                font-weight: $font-regular;
                line-height: 11px;
                color: $black;
                text-decoration: none;
                padding-left: 9px;
                display: none;
            }
            .link_icon {
                display: flex;
                align-items: center;
            }
        }
    }
}