@import "../../../../styles/uitilities.scss";

.right_filtered_category {
  &_options {
    display: flex;
    align-items: center;
  }
  .dashboard_btn {
    min-width: inherit;
    padding: 11px 14px;
    font-size: size(18);
    font-weight: $font-regular;
    line-height: 20px;
    margin: 0 7.5px;
    @include max(1439.98) {
      margin: 0 5px;
      padding: 8px 10px;
    }
    &.rearrange_cancel_btn {
      padding-left: 25px;
      padding-right: 25px;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &.dashboard_dropdown {
      padding: 0;
    }
    svg {
      margin-right: 11px;
      path {
        transition: 0.3s ease all;
        fill: $text-color-alt;
      }
    }
    &:global(.btn-border-grey) {
      color: $text-color-alt;
      background: transparent;
      border: 1px solid $dashboard-add-btn-border;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        color: $dashboard-btn-hover;
        background: $text-color-alt;
        border: 1px solid $text-color-alt;
        svg {
          path {
            fill: $dashboard-btn-hover;
          }
        }
        &::after {
          border-right: 1px solid $dashboard-btn-hover;
          border-bottom: 1px solid $dashboard-btn-hover;
        }
      }
    }
    &.add_btn {
      border: 1px solid $dashboard-add-btn;
      background: transparent;
      color: $dashboard-add-btn;
      svg {
        position: relative;
        top: -2px;
        path {
          fill: $dashboard-add-btn;
        }
      }
      &:hover {
        background: $dashboard-add-btn-bg-hover;
        border: 1px solid $dashboard-add-btn;
        color: $dashboard-add-btn-hover;
        svg {
          path {
            fill: $dashboard-add-btn-hover;
          }
        }
      }
    }
    &:global(.dropdown-toggle) {
      position: relative;
      padding-right: 40px;
      color: $text-color-alt;
      text-transform: capitalize;
      margin-right: 0 !important;
      // max-width: 100% !important;
      // width: 100% !important;
      &:hover {
        color: $dashboard-add-btn-hover;
      }
      &::after {
        position: absolute;
        top: 50%;
        right: 14px;
        width: 7px;
        height: 7px;
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid $text-color-alt;
        border-bottom: 1px solid $text-color-alt;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
  .dashboard_actions {
    &_btn {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: rgba(#000, 0.12);
      border: 1px solid rgba(#000, 0.12);
      padding: 0;
      min-width: inherit;
      position: relative;
      margin-right: 23px;
      &:focus,
      &:active {
        background: rgba(#000, 0.12);
        border: 1px solid rgba(#000, 0.12);
      }
      &_label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4px;
      }
      span {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $text-color-alt;
        + span {
          margin-top: 2px;
        }
      }
      &::after {
        display: none;
      }
    }
    &_close {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: rgba(#000, 0.12);
      // border: 1px solid rgba(#000, 0.12);
      border: none;
      padding: 0;
      min-width: inherit;
      position: relative;

      &:focus,
      &:active {
        background: rgba(#000, 0.12);
        border: 1px solid rgba(#000, 0.12);
      }
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        path {
          fill: $text-color-alt;
        }
      }
    }
  }
  .selection_options {
    display: flex;
    align-items: center;
    .dashboard_btn {
      margin-right: 15px;
    }
    :global(.btn-primary.dropdown-toggle) {
      background-color: rgba(#000, 0.12) !important;
      // border: 1px solid rgba(#000, 0.12) !important;
      border: 1px solid rgba(#000, 0) !important;
    }
    :global(.dropdown-toggle) {
      position: relative;
      &::after {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        border-top: 6px solid rgba($white, 0.46);
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
      }
    }
  }
  :global(.dropdown-menu) {
    background-color: $white;
    border-radius: 10px;
    padding: 15px 13px;
    position: relative;
    transform: translate(0, 51px) !important;
    &::before {
      content: "";
      background: $white;
      position: absolute;
      right: 30px;
      width: 14px;
      height: 14px;
    }
    &[x-placement="bottom-start"] {
      &::before {
        top: 0;
        transform: translateY(-50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 0 0 5px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        transition: 0.5s ease all;
      }
    }
    &[x-placement="bottom-end"] {
      transform: translate(0px, 45px) !important;
      &::before {
        top: 0;
        transform: translateY(-50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 0 0 5px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        transition: 0.5s ease all;
      }
    }
    &[x-placement="top-start"] {
      &::before {
        bottom: 0;
        transform: translateY(50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 5px 0 0;
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
        transition: 0.5s ease all;
      }
    }
    a {
      font-size: size(17);
      font-weight: $font-regular;
      padding: 6px 10px;
      border-radius: 6px;
      overflow: hidden;
      text-transform: capitalize;
      &:hover,
      &:focus {
        color: $text-alt;
        background-color: $dropdown-bg-hover;
      }
    }
  }

  .reArrange_actions {
    margin-right: 20px;
  }
  button{
    height: 42px;
  }
}
.add_to_album{
  :global(.modal-dialog){
    max-width: 335px;
    :global(.modal-content){
      padding: 20px 30px;
    }
    :global(.modal-header){
      height: auto;
      border-bottom: 1px solid $input-border;
      margin-bottom: 13px;
      :global(.modal-title){
        color: $text-color;
      }
    }
    :global(.h4){
      font-size: size(24);
      margin-bottom: 6px;
    }
    :global(.btn-close){
      top: 18px!important;
      right: 19px!important;
      font-size: 15px!important;
    }
  }
}
.add_to_new_album_btn_wrap{
  font-size: size(18);
  font-weight: 500;
  margin: 13px 0;
  display: flex;
  align-items: center;
  .new_album_label{
    padding-left: 15px;
    color: $text-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 58px;
  }
  &:hover {
    .add_to_album_btn {
      color: $text-alt-hover;
    }
  }
}
.add_to_album_btn{
  border: 1px solid $input-border;
  border-radius: 11px;
  color: $text-color;
  width: 58px;
  height: 58px;
  background-color: transparent;
  svg{
    color: currentColor;
    path{
      fill: currentColor;
    }
  }
}
.delete_this_photo_modal{
  color: $text-color;
  :global(.modal-dialog){
    max-width: 474px;
    
  }
  :global(.modal-content){
    padding: 50px 45px;
    text-align: center;
  }
  :global(.modal-header){
    height: auto;
  }
  .delete_btn_wrap{
    display: flex;
    margin-top: 25px;
    button{
      width: 100%;
      max-width: 185px;
      border: none;
      border-radius: 6px;
      font-weight: 500;
      font-size: size(17);
      padding: 13px;
      margin: 0 7px;
      transition: background-color 0.3s ease-in;
    }
    :global(.btn-outline-gray){
      background-color: transparent;
      border: 1px solid $input-border;
      &:hover{
        background-color: rgba(0,0,0,0.05);
      }
    }
    :global(.btn-danger){
      color: white;
      background-color: $delete-red;
      &:hover{
        background-color: $orange-red-alt;
      }
    }
  }
  .delete_title{
    font-size: size(24);
    font-weight: 700;
    margin-bottom: 10px;
    color: $text-color;
  }
  .delete_content{
    font-size: size(16);
    font-weight: 400;
  }
  :global(.btn-close){
    top: 18px!important;
    right: 19px!important;
    font-size: 15px!important;
  }
}

.dashboard_select_dropdown {
  margin-left: -70px;
}

// file upload modal
.dashboard_select_dropdown{
  margin-left: -88px;
  width: 177px;
}
:global(body.dark){
  .dashboard_cancel_btn{
    color: white;
    border-color: white;
    &:hover{
      border-color: #212529;;
      color: white;
    }
  }
}
.dashboard_cancel_btn{
  background-color: transparent;
  padding: 0px;
}

.spinner {
     width: 35px;
    height: 35px;
    margin-left: 50px
}

.albumList{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.listContainer{
    overflow-y: scroll;
    height: 200px
}

.listContainer::-webkit-scrollbar {
  display: none;
}

.gotoAction{
  font-size: 2em;
  font-weight: 500;
  color: gray;
  cursor: pointer;
  
}
