@import '../../../../styles/uitilities.scss';

.mobile_gallery_actions{
    display: flex;
    padding-bottom: 13px;
    border-bottom: 1px solid $input-border;
    margin-bottom: 10px;
    @include max(1279.98) {
      padding-left: 36px;
      padding-right: 36px;
      margin-left: -36px;
      margin-right: -26px;
    }
    @include max(767.98) {
      padding-left: 15px;
      padding-right: 15px;
      margin-left: -15px;
      margin-right: -15px;
    }
    :global(.dropdown-menu) {
        background-color: $white;
        border-radius: 10px;
        padding: 15px 13px;
        position: relative;
        &::before {
          content: "";
          background: $white;
          position: absolute;
          left: 20px;
          width: 14px;
          height: 14px;
        }
        &[x-placement="bottom-start"] {
          transform: translate(-8px, 44px) !important;
          &::before {
            top: 0;
            transform: translateY(-50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 0 0 5px;
            border-bottom: 1px solid $border-color;
            border-left: 1px solid $border-color;
            transition: 0.5s ease all;
          }
        }
        &[x-placement="bottom-end"] {
          transform: translate(0px, 45px) !important;
          &::before {
            top: 0;
            transform: translateY(-50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 0 0 5px;
            border-bottom: 1px solid $border-color;
            border-left: 1px solid $border-color;
            transition: 0.5s ease all;
          }
        }
        &[x-placement="top-start"] {
          &::before {
            bottom: 0;
            transform: translateY(50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 5px 0 0;
            border-bottom: 1px solid #fff;
            border-left: 1px solid #fff;
            transition: 0.5s ease all;
          }
        }
      }
    :global(.dropdown-item) {
      &:active,
      &:focus {
        background-color: #e9ecef !important;
        color: $black;
      }
    }
    .dashboard{
        &_dropdown{
            button{
                &::after{
                    display: none;
                }
            }
            &.show {
              + .btn {
                background-color: $text-color-alt !important;
              }
            }
        }
        &_add_btn{
            margin-left: auto;
            margin-right: 0px;
            border: none;
        }
    }
    button{
        padding: 5px 7px;
        min-width: 35px;
        margin-right: 10px;
    }

    .file_upload{
        display: none;
      }
}
.dashboard_btn{
    background-color: $dashboard-bg;
    width: auto!important;
    margin: 0 10px 0 0!important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    &:focus:hover {
      background-color: $text-color-alt;
    }
    p {
      margin-bottom: 0;
      margin-left: 5px;
      font-size: size(14);
    }
}
.dashboard_add_btn{
    text-transform: capitalize;
    font-size: size(14);
    padding: 9px!important;
    background-color: $blue;
    color: white;
    width: auto!important;
    svg{
        top: -1px;
        position: relative;
        margin-right: 12px;
        path{
            fill: currentColor;
        }
        
    }
    &:hover{
        background-color: $blue-alt;
        border-color: $blue-alt;
    }
}
.dashboard_btn,
.dashboard_dropdown{
    color: $text-color !important;
    svg{
        path{
            fill: currentColor !important;
        }
    }
    &:hover{
        color: white;
    }
}
.dashboard_btn {
  &:hover {
    background-color: $text-color-alt !important;
    svg {
      path {
        fill: $dashboard-btn-hover !important;
      }
    }
  }
}
.add_btn_wrap_mob{
    position: fixed;
    bottom: 72px;
    z-index: 10;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    &:before{
        content: "";
        width: 100%;
        height: 150px;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
        position: absolute;
        left: 0;
        right: 0;
        z-index: 0;
        filter: blur(3px);
        pointer-events: none;
    }
    button{
        width: 90px;
        height: 32px;
        z-index: 10;
    }
    .dashboard_cancel_btn{
        border: none;
    }
    button{
        padding: 5px 7px 8px 7px;
        min-width: 35px;
        margin-right: 10px;
        font-size: size(14);
    }
}

.save_btn_wrap_mob{
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid $input-border;
    button{
        width: 90px;
        height: 32px;
        z-index: 10;
    }
    .dashboard_cancel_btn{
        background-color: transparent;
        &:hover{
            background-color: $secondary-color;
            border-color: $secondary-color;
        }
    }
    button{
        padding: 5px 7px 8px 7px;
        min-width: 35px;
        margin-right: 10px;
        font-size: size(14);
    }
}

:global(body.dark){
    .save_btn_wrap_mob{
        .dashboard_cancel_btn{
            color: $text-color;
            border-color: $text-color;
            &:hover{
                background-color: white;
                color: black;
            }
        }
    }
}

.dashboard_actions {
    &_btn {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: rgba(#000, 0.12);
      border: 1px solid rgba(#000, 0.12);
      padding: 0;
      min-width: inherit;
      position: relative;
      margin-right: 23px;
      &:focus,
      &:active {
        background: rgba(#000, 0.12);
        border: 1px solid rgba(#000, 0.12);
      }
      &_label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4px;
      }
      &:hover {
        background: $text-color-alt;
        span {
          background: $dashboard-btn-hover;
        }
      }
      span {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $text-color-alt;
        + span {
          margin-top: 2px;
        }
      }
      &::after {
        display: none;
      }
    }
    &_close {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: rgba(#000, 0.12);
      border: none;
      padding: 0;
      min-width: inherit;
      position: relative;
      &:hover {
        background-color: $text-color-alt;
        svg {
          path {
            fill: $dashboard-btn-hover;
          }
        }
      }
      &:focus,
      &:active {
        background: rgba(#000, 0.12);
        border: 1px solid rgba(#000, 0.12);
      }
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        path {
          fill: $text-color-alt;
        }
      }
    }
 
  }
