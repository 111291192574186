@import "../../../styles/uitilities.scss";

.otp_verification {
  padding: 74px 45px 56px;
  @include max(374.98) {
    padding: 74px 30px 56px;
  }
  &_header {
    margin-bottom: 44px;
    text-align: center;
  }
  &_title {
    font-size: size(26);
    font-weight: $font-bold;
    color: $text-color-alt;
    margin-bottom: 29px;
  }
  &_info {
    font-size: size(18);
    font-weight: $font-regular;
    line-height: 28px;
    color: $text-color-alt;
    max-width: 378px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    button {
      color: $modal-link;
      border: none;
      background: none;
      margin-left: 10px;
      font-size: size(18);
      font-weight: $font-regular;
      text-decoration: underline;
      text-underline-offset: 1px;
      transition: 0.3s ease all;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .otp_inputs {
    :global(.form-group) {
      margin-bottom: 0;
      > div {
        justify-content: center;
        align-items: center;
      }
    }
    :global(.otp-field) {
      justify-content: center;
    }
    input {
      background-color: $input-background;
      caret-color: $text-color-alt;
      border-color: $otp-border;
      color: $text-color-alt;
      &:focus {
        border-color: $border-color-alt;
      }
    }
  }
  .otp_error_message {
    display: flex;
    justify-content: center;
    font-size: size(13);
    font-weight: $font-regular;
    line-height: 14px;
    color: $error-message;
    padding-top: 9px;
  }
  &_resend_otp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 29px;
    span {
      font-size: size(15);
      font-weight: $font-medium;
      color: $text-color-alt;
      padding-right: 5px;
    }
    button {
      font-size: size(15);
      font-weight: $font-medium;
      color: $modal-link;
      border: none;
      background: none;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .signin_button {
    margin-top: 31px;
  }

  .input_Style_error {
    border-color: #f85e5e !important;
    box-shadow: 0 0 0 1px #f85e5e;
  }
}
