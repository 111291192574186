.searchprofiletabs {
  ul {
    display: flex;
    align-items: end;
    height: 45px;
    list-style-type: none;
    li {
      padding-right: 20px;
    }
  }
  .searchprofiletab_content {
    margin-left: -90px;
    height: 550px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 10px;
    }
  }
}
