@import "../../styles/uitilities.scss";

.image_edit {
    @include max(991.98) {
        height: 100%;
        overflow: hidden;
        width: 100vw;
    }
    &_actions {
        position: fixed;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        max-width: 400px;
        width: 100%;
        height: 100px;
        z-index: 9;
        padding: 0 15px;
        box-shadow: 3px 0 9px #07145422;
        border-top: 1px solid #07145412;
        background: $dashboard-bg-alt;
        @include max(1199.98) {
            max-width: 320px;
            width: 100%;
            bottom: 60px;
        }
        :global(.btn-wrap) {
            padding: 0 5px;
            flex: 0 0 50%;
            max-width: 50%;
            button {
                min-width: 100%;
            }
        }
    }
    &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 55px;
        position: relative;
        border-bottom: 1px solid $dashboard-header-border;
        margin-top: 10px;
        @include max(1199.98) {
          padding: 12px 16px;
        }
        @include max(991.98) {
            margin-top: 0;
        }
        :global(.btn) {
          min-width: inherit;
          height: 35px;
          font-size: size(17);
          padding: 8px 25px;
          &:first-child {
            margin-right: 10px;
          }
        }
        .back_button {
          border: none;
          background-color: transparent;
          font-size: size(17);
          font-weight: $font-regular;
          display: flex;
          align-items: center;
          color: $text-color-alt;
          a {
            color: $text-color-alt;
          }
          svg {
            margin-right: 10px;
            path {
              fill: $text-color-alt;
            }
          }
        }
    }
    &_actions {
      display: flex;
    }
    &_save {
      margin-right: 20px;
    }
    &_display {
        display: flex;
        @include max(991.98) {
            flex-direction: column;
            height: calc(100vh - 155px);
        }
        .image_wrap {
            background: $dashboard-bg;
            height: calc(100vh - 84px);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 50px 150px;
            flex: 1;
            @include max(1599.98) {
                padding: 0 100px;
            }
            @include max(1279.98) {
                height: calc(100vh - 95px);
                padding: 0 50px;
            }
            @include max(575.98) {
                padding: 0 15px;
            }
        }
    }
    // :global(.ReactCrop) {
    //     width: 100%;
    //     height: 100%;
    // }
    // :global(.ReactCrop img) {
    //     width: 100%;
    //     height: 100%;
    //     object-fit: contain;
    // }
    // :global(.ReactCrop__child-wrapper) {
    //     width: 100%;
    //     height: 100%;
    // }
    &_image {
        position: relative;
        overflow: hidden;
        margin-bottom: 0;
        width: 100%;
        height: 100%;
        max-width: 840px;

        display: flex;
        justify-content: center;
        align-items: center;
        @include max(991.98) {
            max-width: 620px;
        }
        canvas {
            width: 100% !important;
            height: 100% !important;
        }
        img { 
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
            max-width: 100%;
            max-height: 100%; 
        }
        // &::after {
        //     content: "";
        //     display: block;
        //     height: 0;
        //     width: 100%;
        //     padding-bottom: calc(621 / 1084 * 100%);
        // }
    }
    &_sidebar {
        flex: 0 0 400px;
        max-width: 400px;
        max-height: calc(100vh - 84px);
        background: $white;
        background-color: $dashboard-bg-alt;
        box-shadow: 3px 0 9px #07145422;
        @include min(992) {
            position: sticky;
            overflow: auto;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        @include max(1279.98) {
            flex: 0 0 350px;
            max-width: 350px;
            height: calc(100vh - 95px);
        }
        @include max(991.98) {
            flex: 0 0 175px;
            max-width: 100%;
            width: 100%;
            height: inherit;
            padding: 15px;
        }
        :global(.accordion .accordion-item) {
            border: none;
            border-radius: 0px !important;
            background-color: $dashboard-menu-active-bg;
        }
        :global(.accordion .accordion-body) {
            background-color: $dashboard-bg-alt;
        }
        :global(.accordion .accordion-header button) {
            background-color: $dashboard-menu-active-bg;
            color: $dashboard-menu-active;
            font-size: size(18);
            font-weight: $font-medium;
            svg {
                margin-right: 10px;
                path {
                    fill: $dashboard-menu-active;
                }
            }
            &::before {
                content: "";
                width: 2px;
                height: 20px;
                background: $dashboard-menu-active;
                border: none;
                position: absolute;
                top: 50%;
                right: 30px;
                transform: translateY(-50%) rotate(90deg);      
            }
            &::after {
                content: "";
                width: 2px;
                height: 20px;
                background: $dashboard-menu-active;
                border: none;
                position: absolute;
                top: 50%;
                right: 30px;
                transform: translateY(-50%) rotate(90deg);
                transition: 0.3s ease transform;
            }
        }
        :global(.accordion-item:not(:last-of-type) .accordion-header .accordion-button.collapsed) {
            border-bottom: 1px solid rgba(#dedede, 0.2);
        }
        :global(.accordion-header .accordion-button.collapsed::after) {
            transform: translateY(-50%) rotate(0deg);
        }
        .image_edit_tab_nav {
            border-bottom: 1px solid $border-opacity-dark;
            position: sticky;
            top: 0;
            z-index: 9;
            background-color: $dashboard-bg-alt;
            flex-wrap: nowrap;
            :global(.nav-link) {
                background-color: transparent;
                border-right: 1px solid $border-opacity-dark;
                padding: 10px 30px;
                font-size: size(16);
                font-weight: $font-medium;
                border-radius: 0;
                color: $text-color-alt;
                @include max(1279.98) {
                    padding: 10px 20px;
                }
                svg {
                    margin-right: 10px;
                    path {
                        fill: $text-color-alt
                    }
                }
            }
            :global(.nav-link.active) {
                background-color: $modal-link !important;
                color: $white !important;
                svg {
                    path {
                        fill: $white;
                    }
                }
            }
        }
        :global(.tab-content) {
            padding: 25px;
            height: calc(100vh - 205px);
            overflow: auto;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            @include max(991.98) {
                overflow: auto;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            @include max(575.98) {
                padding: 10px 0;
            }
        }
        .edit_list {
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            @include max(991.98) {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -15px;
            }
            &_actions {
                display: flex;
                margin: 0 -5px;
                margin-top: 10px;
                :global(.btn-wrap) {
                    padding: 0 5px;
                    flex: 0 0 50%;
                    max-width: 50%;
                    button {
                        min-width: 100%;
                        &:global(.btn-green) {
                            &:focus {
                                color: $white;
                                background-color: #098f11;
                                border-color: #098f11;
                            }
                        }
                    }
                }
            }
            // .crop_dropdown {
            //     flex: 0 0 100%;
            //     max-width: 100%;
            //     @include max(991.98) {
            //         padding: 0 15px;
            //         max-width: 474px;
            //         margin-left: auto;
            //         margin-right: auto;
            //     }
            //     > button {
            //         background-color: #23cc2b;
            //         border-color: #23cc2b;
            //         transition: 0.3s ease all;
            //         @include max(991.98) {
            //             width: inherit;
            //             margin-left: 0;
            //             margin-bottom: 10px;
            //             min-width: inherit;
            //             padding: 10px 20px;
            //             font-size: size(15);
            //         }
            //         @include max(575.98) {
            //             max-width: 100%;
            //         }
            //         &:hover {
            //             filter: brightness(0.9);
            //         }
            //         svg {
            //             margin-right: 5px;
            //             @include max(991.98) {
            //                 width: 15px;
            //                 height: 15px;
            //             }
            //             path {
            //                 fill: $white;
            //             }
            //         }
            //     }
            // }
            > li {
                margin-bottom: 15px;
                @include max(991.98) {
                    margin-bottom: 5px;
                    &:not(.crop_dropdown) {
                        flex: 0 0 50%;
                        max-width: 50%;
                        padding: 0 15px;
                        @include min-max(576, 991.98) {
                            flex: 0 0 auto;
                            max-width: inherit;
                            min-width: 250px;
                        }
                    }
                }
                &:not(.crop_open) {
                    ul {
                        height: 0;
                        opacity: 0;
                        visibility: hidden;
                        min-height: 0;
                        max-height: 0;
                        @include max(991.98) {
                            display: none;
                        }
                    }
                }
                > button {
                    margin-bottom: 15px;
                }
                ul {
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style: none;
                    display: flex;
                    flex-wrap: wrap;
                    opacity: 1;
                    visibility: visible;
                    min-height: 0px;
                    max-height: 500px;
                    will-change: height, opacity;
                    transition: 0.3s ease all;
                    margin: 0 -5px;
                    @include min(992) {
                        height: 100%;
                    }
                    @include max(991.98) {
                        margin: 0 -5px;
                        display: flex;
                    }
                    li {
                        flex: 0 0 33.3333%;
                        max-width: 33.3333%;
                        padding: 0 5px;
                        margin-bottom: 10px;
                        @include min-max(576, 991.98) {
                            flex: 0 0 auto;
                            max-width: inherit;
                        }
                    }
                    .small_btn {
                        padding: 8px 15px;
                        line-height: 1;
                        width: 100%;
                        min-width: inherit;
                        font-weight: $font-regular;
                        font-size: size(16);
                        background-color: $button-dark-alt;
                        border-color: $button-dark-border-alt;
                        color: $text-color-alt;
                    }
                }
            }
            &_option {
                &_title {
                    font-size: size(16);
                    font-weight: $font-regular;
                    color: $text-color-alt;
                    @include max(991.98) {
                        font-size: size(13);
                        margin-bottom: 5px;
                    }
                }
                input {
                    &::-webkit-slider-thumb {
                        background: $modal-link;
                        @include max(991.98) {
                            width: 12px;
                            height: 12px;
                            margin-top: -3px;
                        }
                    }
                    &::-webkit-slider-runnable-track {
                        background: $slider-track;
                        @include max(991.98) {
                            height: 6px;
                        }
                    }
                    &:focus {
                        &::-webkit-slider-thumb {
                            box-shadow: none !important;
                            outline: none !important;
                        }
                    }
                }
            }
        }
        .filter_list {
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            margin-left: -7.5px;
            margin-right: -7.5px;
            padding-top: 5px;
            @include max(991.98) {
                flex-wrap: nowrap;
                overflow-x: auto;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            &_image {
                cursor: pointer;
                @include max(991.98) {
                    margin-bottom: 0 !important;
                }
                @include max(575.98) {
                    margin-bottom: 15px !important;
                }
                &:hover {
                    img {
                        transform: scale(1.03);
                    }
                }
            }
            li {
                padding-left: 7.5px;
                padding-right: 7.5px;
                @include max(991.98) {
                    min-width: 140px;
                    max-width: 150px;
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
            &_image {
                margin-bottom: 15px;
                overflow: hidden;
                position: relative;
                img {
                    width: 100%;
                    transition: 0.3s ease all;
                }
                &_data {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 2;
                    background: rgba($black, 0.7);
                    backdrop-filter: saturate(180%) blur(20px);
                    width: 100%;
                    padding: 5px 20px;
                    color: $white;
                }
            }
        }
    }
    .edit_menu_mob {
        > :global(.row) {
            flex-direction: column-reverse;
        }
        :global {
            .nav-item {
                flex: 1;
                .nav-link {
                    background-color: transparent;
                    text-align: center;
                    border: 1px solid rgba($border-color, 0.3);
                    border-radius: 0px;
                    color: $text-color-alt;
                    &.active {
                        color: $dashboard-btn-hover !important;
                        background-color: $text-color-alt;
                        svg {
                            path {
                                fill: $dashboard-btn-hover;
                            }
                        }
                    }
                    svg {
                        path {
                            fill: $text-color-alt;
                        }
                    }
                }
            }
        }
    }
}
.crop_modal {
    &:global(.fade) {
        transition: 0s linear opacity;
    }
    :global(.modal-body) {
        padding: 30px 30px 20px !important;
    }
    :global(.modal-dialog) {
        max-width: 768px;
    }
    :global(.react-crop) {
        width: 100%;
    }
    :global(.ReactCrop__child-wrapper) {
        max-height: 500px;
        img {
            width: 100%;
            object-fit: contain;
        }
    }
    &_contents {
        width: 100%;
    }
    &_title {
        font-size: size(28);
        font-weight: 500;
        text-align: center;
        color: $text-color-alt;
        margin-bottom: 15px;
        line-height: 1;
        @include max(991.98) {
            font-size: size(24);
        }
    }
    &_description {
        font-size: size(16);
        font-weight: 400;
        text-align: center;
        color: $text-color-alt;
        max-width: 576px;
        margin: 0 auto;
        margin-bottom: 20px;
        line-height: 1.3;
        @include max(575.98) {
            max-width: 250px;
        }
    }
    &_image {
        position: relative;
        overflow: hidden;
        margin-bottom: 0;
        width: 100%;
        height: 100%;
        img { 
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
            max-width: 100%;
            max-height: 100%; 
        }
        &::after {
            content: "";
            display: block;
            height: 0;
            width: 100%;
            padding-bottom: calc(621 / 935 * 100%);
        }
    }
    &_actions {
        display: flex;
        justify-content: flex-end;
        margin: 0 -5px;
        border-top: 1px solid rgba($border-color, 0.3);
        padding-top: 20px;
        margin-top: 20px;
        @include max(575.98) {
            justify-content: center;
        }
        .btn_wrap {
            padding: 0 5px;
            @include max(575.98) {
                flex: 0 0 50%;
                max-width: 50%;
                :global(.btn) {
                    width: 100%;
                    min-width: inherit;
                    padding: 12px 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}