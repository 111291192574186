@import "../../../styles/uitilities.scss";

.contact_page {
    background: $contact-page-bg;
    .contact {
        &_banner {
            background: #dfdfdf;
            position: relative;
            &::after {
                content: "";
                width: 100%;
                height: 50%;
                background-image: linear-gradient(180deg, #FFFFFF00 0%, $contact-page-bg 100%);
                position: absolute;
                bottom: 0;
                left: 0;
                @include max(991.98) {
                    height: 100%;
                }
            }
            :global(.container) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                h1 {
                    margin-bottom: 0;
                }
            }
            &_image {
                margin-bottom: 0;
                @include max(1279.98) {
                    height: 300px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: left;
                }
            }
        }
        &_title {
            font-size: size(32);
            font-weight: $font-medium;
            color: $text-color-alt;
            margin-bottom: 26px;
            width: max-content;
            position: relative;
            &::after {
                content: "";
                width: 33px;
                height: 4px;
                background: #267FFD;
                border-radius: 22px;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(140%, -50%);
                @include max(575.98) {
                    display: none;
                }
            }
        }
        &_email {
            &_label {
                font-size: size(18);
                font-weight: $font-regular;
                color: #267FFD;
                margin-bottom: 20px;
                display: block;
                line-height: 1;
            }
            &_address {
                font-size: size(18);
                font-weight: $font-regular;
                color: $contact-input;
                transition: 0.3s ease color;
                &:hover {
                    color: $primary-color;
                    text-decoration: underline;
                }
            }
        }
        &_contents {
            padding-top: 36px;
            position: relative;
            &_head {
                width: max-content;
                position: relative;
                z-index: 2;
                @include max(1279.98) {
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                }
            }
            .contact_wrap {
                display: flex;
                justify-content: space-between;
                @include max(1279.98) {
                    flex-direction: column-reverse;
                }
                .contact_contents_head {
                    @include max(1279.98) {
                        padding-bottom: 60px;
                    }
                }
            }
        }
        &_form_wrap {
            margin-top: -340px;
            padding-bottom: 158px;
            position: relative;
            @include max(1599.98) {
                margin-top: -280px;
            }
            @include max(1279.98) {
                margin-top: 0;
                padding-top: 20px;
                padding-bottom: 40px;
            }
            @include max(575.98) {
                padding-top: 0;
            }
            .anim_elem {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -50%);
                @include max(991.98) {
                    display: none;
                }
            }
        }
    }
}