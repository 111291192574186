@import "../../../styles/uitilities.scss";

.gallery_filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: max(356px, 100%);
  padding-top: 14px;
  padding-bottom: 14px;
  position: relative;
  border-bottom: 2px solid $input-border;
  min-height: 72px;
  &_wrap {
    @include max(575.98) {
      overflow: auto;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
