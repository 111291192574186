@import '../../styles/uitilities.scss';

.share_modal {
    :global(.modal-dialog) {
        max-width: 292px;
    }
    :global(.modal-content) {
        padding: 25px;
    }
    &_title {
        font-size: size(24);
        font-weight: $font-medium;
        color: $text-color-alt;
        padding-bottom: 11px;
        margin-bottom: 22px;
        border-bottom: 1px solid #E0E2E8;
    }
    &_options {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
            font-size: size(18);
            font-weight: $font-regular;
            color: $text-color;
            &:not(:last-child) {
                margin-bottom: 23px;
            }
            svg {
                margin-right: 21px;
            }
        }
        &_button {
            background-color: transparent;
            border: none;
            display: flex;
            align-items: center;
            &:hover {
                color: $modal-link;
            }
        }
    }
}