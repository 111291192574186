@import "../../../styles/uitilities.scss";

.User_profile_overview{
    &_right_side,
    &_main_col{
        width: 100%;
    }
    &_sec{
        width: 100%;
        background-color: $dashboard-header-bg;
        padding: 30px 28px;
        @include min(768){
            border-radius: 10px;
            margin: 25px 0;
        }
        @include max(767.98){
            margin: 10px 0;
        }
    }
    &_title{
        font-weight: 500;
        color: $text-color;
        margin-bottom: 20px;
        @include min(1200){
        font-size: size(24);
        }
        @include min-max(576,1199.98){
        font-size: size(20);
        }
        @include max(575.98){
        font-size: size(16);
        }
    }
    &_p{
        color: $text-color;
        @include min(1200){
        font-size: size(16);
        }
        @include min-max(576,1199.98){
        font-size: size(15);
        }
        @include max(575.98){
        font-size: size(14);
        }
    }
    
}






