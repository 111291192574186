@import "../../../../../styles/uitilities.scss";

.file_upload {
  &_modal {
    :global(.modal-dialog) {
      @include min(768) {
        max-width: 836px;
      }
    }
    :global(.modal-content) {
      padding: 48px 40px 40px;
    }
  }
  &_draggable_area {
    width: 100%;
    height: 356px;
    border-radius: 6px;
    border: 2px dashed #23b070;
    background: $file-upload-bg;
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
  }
  &_image {
    margin-bottom: 23px;
  }
  &_input {
    display: none;
    &_label {
      color: #23cc2b;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        filter: brightness(0.8);
      }
    }
  }
  &_info {
    font-size: size(20);
    font-weight: $font-medium;
    margin-bottom: 10px;
    color: $text-color-alt;
    &_desc {
      font-size: size(17);
      font-weight: $font-regular;
      line-height: 28px;
      color: $text-color-alt;
      margin-bottom: 0;
    }
  }
  &_preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    :global(.swiper) {
      flex: 1;
    }
    :global(.swiper-slide) {
      width: 111.4px !important;
    }
    &_wrap {
      // max-width: 112px;
      width: 100%;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      &.blank_image {
        border: 1px dashed #bfc4ce7c;
      }
      svg {
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 20px;
        height: 20px;
        fill: transparent;
      }
      .success_indicator {
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 5px;
        right: 5px;
        background: #21c031;
        border-radius: 50%;
        .success_check {
          width: 12px;
          height: 6px;
          border: 1px solid transparent;
          border-bottom: 1px solid #fff;
          border-left: 1px solid #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -70%) rotate(-45deg);
        }
      }
    }
    &_image {
      position: relative;
      margin-bottom: 0;
      background: $file-preview-bg;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        // object-fit: cover;
        max-width: 100%;
        max-height: 100%;
      }
      &::after {
        content: "";
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: calc(83 / 112 * 100%);
      }
    }
    .file_upload_input_2 {
      display: none;
    }
    .add_more_btn {
      flex: 0 0 112px;
      max-width: 112px;
      width: 100%;
      height: 100%;
      position: relative;
      margin-bottom: 0;
      border: 1px dashed #bfc4ce7c;
      border-radius: 6px;
      background: $file-preview-bg;
      margin-left: 17px;
      &_contents {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: size(15);
        font-weight: $font-regular;
        color: $text-color-alt;
        svg {
          margin-bottom: 3px;
          path {
            fill: $text-color-alt;
          }
        }
      }
      &::after {
        content: "";
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: calc(83 / 112 * 100%);
      }
      &:hover {
        background: #23cc2b;
        .add_more_btn_contents {
          color: #fff;
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
  &_btn_wrap {
    text-align: center;
    margin-top: 27px;
    :global(.btn) {
      min-width: 136px;
    }
  }
  &_delete {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #00000064;
    box-shadow: 0px 3px 4px #0000001c;
    border: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      path {
        fill: #fff;
      }
    }
    &:hover {
      background: #ffffff64;
      svg {
        path {
          fill: $text-color-alt;
        }
      }
    }
  }
}

//jfbghhbdghvdebfhbeijfbhvgeihfbg
.modalWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  .modalContent {
    min-width: 700px;
    min-height: 500px;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    .dropArea {
      width: 100%;
      height: 400px;
      border-radius: 5px;
      transition: 0.5s;
      border: 3px dashed #519676;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        font-size: 15px;
        color: rgba(0, 0, 0, 0.5);
      }
      label {
        color: #3ac747;
        text-decoration: underline;
        cursor: pointer;
      }
      input {
        display: none;
      }
    }
    .preview {
      width: 600px;
      height: 100px;
      margin-top: 10px;
      white-space: nowrap;

      // display: flex;
      // justify-content: flex-start;
      // align-items: center;
      float: right;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .preview_placeholder {
        margin-right: 20px;
        width: 16%;
        height: 90%;
        padding: 20px;
        float: right;
        border: 1px dashed rgba(0, 0, 0, 0.2);
        &:last-child {
          margin: 0;
        }
      }
      .preview_image {
        display: inline-block;
        width: 120px;
        height: 85%;
        margin-right: 12px;
        border-radius: 3px;
        overflow: hidden;
        &:last-child {
          margin-right: 0;
        }
        figure {
          height: 100%;
          width: 100%;
          img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            transition: 0.3s;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    .btn_holder {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        width: 70px;
        height: 40px;
        border-radius: 5px;
        border: none;
      }
      .active {
        background-color: #3ac747;
        color: white;
        transition: 0.3s;
        &:hover {
          background-color: #21c031;
        }
      }
    }
  }
}

.file_upload_modal_form {
  @include max(768) {
    max-width: 350px;
  }
}
