@import '../../../../styles/uitilities.scss';

.root {
  background-color: $card-bg;
  flex-wrap: wrap;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 234px;
  padding: 20px 25px;
  border-radius: 6px;
  margin-left: 30px;
  @include max(991.98) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 102%;
    margin-left: 0;
    padding: 0 16px;
    background: $dashboard-bg;
  }
  &::after {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid $card-bg;
    position: absolute;
    left: -10px;
    bottom: 9%;
    z-index: 1;
  }
  .innerWrapper {
    width: 100%;
    position: relative;
  }
  .heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    @include max(991.98) {
      text-align: center;
    }
    h3 {
      font-size: 18px;
      line-height: 1.5;
      color: $text-color-alt;
      text-decoration: none;
      font-weight: 600;
      width: 100%;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
}

.listWrapper {
  width: 100%;
  position: relative;
  overflow: scroll;
  height: 160px;
  &::-webkit-scrollbar {
    display: none;
  }
  .listItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
  }
}

.item {
  width: 100%;
  max-width: 100%;
  position: relative;
  max-width: 202px;
  padding: 5px;
  background-color: $tab-active-bg;
  border-radius: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  border: 1px solid $tab-active-bg;
  @include max(991.98) {
    width: 100%;
    max-width: 100%;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .imageWrapper {
    width: 50px;
    display: flex;
    .imageContainer {
      width: 100%;
      position: relative;
      padding-bottom: 100%;
      border-radius: 50%;
      overflow: hidden;
      background-color: $placeholder-image;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  .textWrapper {
    width: auto;
    position: relative;
    font-size: 16px;
    line-height: 1.5;
    color: $text-color-alt;
    text-decoration: none;
    font-weight: 500;
    padding: 0px 15px;
  }
}
