@import "../../../styles/uitilities.scss";

.slideshow_fullscreen {
  background: #212124;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  &_image {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100vh;
      max-width: 100%;
      object-fit: contain;
    }
  }
  &_controls {
    background-color: #2C2C34;
    color: white;
    padding: 15px 10px;
    border-radius: 7px;
    position: absolute;
    bottom: 39px;
    left: 46px;
    z-index: 2;
    @include max(767.98) {
      bottom: 24px;
      left: 17px;
    }
    .control_btn {
      padding: 0 18px;
      background-color: transparent;
      border: none;
      position: relative;
      &::after {
        content: "";
        width: 44px;
        height: 44px;
        background: #ffffff12;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: 0.3s ease all;
      }
      &:hover {
        &::after {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }
  :global {
    .swiper-button-next {
      display: none;
    }
    .swiper-button-prev {
      display: none;
    }
  }
  :global(.fade) {
    animation: fade 4s ease 1 forwards;
    transition: 5s ease all;
  }
  // :global(.swiper-slide-active) {
  //   opacity: 1 !important;
  // }
  :global(.bounce) {
    opacity: 1;
    animation: bounce 3s ease 1 forwards;
    transition: 2s ease all;
  }
  :global(.zoom-out) {
    animation: zoom-out 3s ease 1 forwards;
    transition: 2s ease all;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1, 1) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes zoom-out {
  0% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
