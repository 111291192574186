@import "../../../styles/uitilities.scss";

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 475px;
  box-shadow: -2px 0px 8px rgba(7, 20, 84, 0.07);
  position: fixed;
  top: 83px;
  right: 0;
  z-index: 99;
  height: calc(100vh - 84px);
  padding: 0px 50px;
  background-color: $dashboard-bg-alt;
  justify-content: center;
  align-items: center;
  animation: slideAnim 0.3s;
  padding-bottom: 0;
  @include max(1279.98) {
    top: 0;
    padding-top: 62px;
    padding-bottom: 61px;
    height: 100vh;
  }
  @include max(1199.98) {
    padding-top: 52px;
  }
  @include max(575.98) {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .sideDrawerWrapper {
    width: 100%;
    overflow-y: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.closeWrapper {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 40px;
  font-size: 0px;
  border-radius: 50%;
  z-index: 999;
  overflow: hidden;
  position: absolute;
  top: 32px;
  right: 20px;
  @include max(1279.98) {
    top: 85px;
  }
  button {
    font-size: 0px;
    border: none;
    padding: 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s background-color ease-in-out;
    &::after {
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: $text-color-alt;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: 0.3s background-color ease-in-out;
    }
    &::before {
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: $text-color-alt;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      transition: 0.3s background-color ease-in-out;
    }
    &:hover {
      opacity: 0.5;
    }
  }
}

.drawerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
}

.drawerImage {
  width: 100%;
  position: relative;
  max-width: 200px;
  border-radius: 50%;
  border: 3px solid #e0e2e8;
  padding: 10px;
  margin: 0px auto 30px auto;
  .drawerImageContainer {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    border-radius: 50%;
    background-color: #e0e2e8;
    overflow: hidden;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .drawerImageIcon {
    position: absolute;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;
    padding: 5px;
    border: 4px solid #fff;
    bottom: 20px;
    right: -5px;
    box-shadow: 0px 3px 6px rgba(211, 213, 219, 0.44);
    > label {
      background-color: #23cc2b;
      display: inline-block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.207' height='17.286' viewBox='0 0 19.207 17.286'%3E%3Cg id='Iconly_Bold_Camera' data-name='Iconly/Bold/Camera' transform='translate(-2 -3)'%3E%3Cg id='Camera' transform='translate(2 3)'%3E%3Cpath id='Path_76340' data-name='Path 76340' d='M11.86,0a2.345,2.345,0,0,1,2.132,1.353c.114.228.273.561.44.912l.2.424h0l.1.208.1.211a.218.218,0,0,0,.192.115,4.2,4.2,0,0,1,4.187,4.182h0v5.7a4.2,4.2,0,0,1-4.187,4.182H4.187A4.188,4.188,0,0,1,0,13.1H0v-5.7A4.188,4.188,0,0,1,4.187,3.223a.188.188,0,0,0,.182-.115h0l.058-.115c.269-.566.6-1.257.787-1.64A2.331,2.331,0,0,1,7.337,0H11.86ZM9.6,6.149A3.766,3.766,0,0,0,6.924,7.262,3.711,3.711,0,0,0,5.829,9.919,3.776,3.776,0,1,0,9.6,6.149Zm0,1.439A2.333,2.333,0,1,1,7.27,9.919h0v-.01a2.253,2.253,0,0,1,.672-1.631A2.326,2.326,0,0,1,9.6,7.588Zm5.387-1.669a.868.868,0,1,0,.874.873A.868.868,0,0,0,14.991,5.919Z' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center;
    }
    input {
      font-family: "Ubuntu", sans-serif;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
    }
  }
}

.fieldWrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .fieldGroup {
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    .date_input {
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        pointer-events: none;
        path {
          fill: $text-color-alt;
        }
      }
    }
    label {
      color: $input-label;
      font-size: 14px;
      line-height: 1.5;
      text-decoration: none;
      font-weight: 500;
      display: block;
      width: 100%;
      margin-bottom: 12px;
    }
    input[type="date" i]::-webkit-calendar-picker-indicator {
      opacity: 0;
      cursor: pointer;
    }
    input,
    .textarea {
      font-family: "Ubuntu", sans-serif;
      width: 100%;
      position: relative;
      height: 50px;
      background-color: $input-background;
      border: 1px solid $input-border;
      color: $text-color-alt;
      border-radius: 10px;
      overflow: hidden;
      padding: 10px;
      font-size: 16px;
      line-height: 1;
      text-decoration: none;
      font-weight: 400;
    }
    .textarea {
      height: 65px;
      textarea {
        resize: none;
        overflow: auto;
        border: none;
        width: 100%;
        height: 100%;
        background-color: $input-background;
        color: $text-color-alt;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .checkBoxWrapper {
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    .checkBox_buttons {
      display: flex;
    }
    .title {
      width: 100%;
      position: relative;
      color: $input-label;
      font-size: 14px;
      line-height: 1.5;
      text-decoration: none;
      font-weight: 600;
      display: block;
      margin-bottom: 12px;
      margin-top: 0;
    }
    .checkBoxItem {
      width: 100%;
      // width: max-content;
      border-radius: 30px;
      border: 1px solid #d3d5db;
      height: 50px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: 0.3s all ease-in-out;
      label {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 0 34px;
      }
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        border: 1px solid rgb(151, 151, 151);
      }
      &.checked {
        background-color: #1d5eff;
        border: 1px solid #1d5eff;
        color: #fff;
        label {
          color: #fff;
        }
      }
    }
    label {
      color: $text-color-alt;
      font-size: size(16);
      line-height: 1.5;
      text-decoration: none;
      font-weight: $font-medium;
      display: block;
      width: 100%;
      margin-bottom: 0px;
      transition: 0.3s all ease-in-out;
    }
    input {
      font-family: "Ubuntu", sans-serif;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
      cursor: pointer;
    }
  }
}

.actionButton {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10px;
  .btnItem {
    width: 100%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background-color: #085cfd;
    color: #fff;
    border: none;
    min-height: 50px;
    font-size: 14px;
    line-height: 1.5;
    text-decoration: none;
    font-weight: 600;
    display: block;
    width: 100%;
    margin-bottom: 0px;
    text-align: center;
    cursor: pointer;
  }
}

.deleteBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  button {
    font-size: 15px;
    line-height: 1;
    color: #fc4343;
    text-decoration: none;
    font-weight: 500;
    text-decoration: underline;
    width: auto;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}

.error {
  font-size: 12px;
  line-height: 1.5;
  color: #f00;
  margin: 10px 0px;
}
@keyframes slideAnim {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
