@import "../../../styles/uitilities.scss";

@mixin imgwrap($w,$h){
    $val: calc( 10000 / $w * $h);
    $val1: floor($val);
    $val2: calc($val1 / 100);
    position: relative;
    figure{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        img{
            width: 100%;
            height: 100%;
        }
    }
    &:before{
        content: "";
        position: relative;
        display: block;
        width: 100%;
        padding-bottom: ($val2 * 1%);
        pointer-events: none;
    }
}

.album_item{
    position: relative;
    width: 100%;
    @include min(1350){
        // max-width: 311px;
        // padding: 0 23px;
        // margin-bottom: 46px;
        &_name{
            font-size: size(22);
        }
        &_dis{
            font-size: size(14);
        }
    }
    
    @include min-max(1200,1349.98){
        // max-width: 250px;
        // padding: 18px;
        &_name{
            font-size: size(20);
        }
        &_dis{
            font-size: size(14);
        }
    }
    @include min-max(768,1499.98){
        // max-width: 25%;
    }
    @include min-max(768,1199.98){
        // padding: 15px 10px;
        &_name{
            font-size: size(18);
        }
        &_dis{
            font-size: size(13);
        }
    }
    @include min-max(576,767.98){
        // max-width: 33.3333%;
        // padding: 11px;
        &_name{
            font-size: size(16);
        }
        &_dis{
            font-size: size(12);
        }
    }
    @include max(575.98){
        // max-width: 50%;
        // padding: 7px;
        &_name{
            font-size: size(14);
        }
        &_dis{
            font-size: size(12);
        }
    }
    @include max(374.98) {
        // max-width: 100%;
    }
    &:global(.album-initial-visibility) {
        opacity: 0;
        transform: translateY(20px);
    }
    &:global(.album-visible) {
        animation: albumLoadAnim 0.5s ease-out 1 forwards;
    }
    
    .shimmer-styles {
        width: 100%;
        background: $shimmer-bg-alt;
        background-image: $shimmer-gradient-alt;
        background-repeat: no-repeat;
        background-size: 800px 100%;
        border-radius: 5px;
        
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: forwards; 
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-name: placeholderShimmer;
        -webkit-animation-timing-function: linear;
    }

    &_img_wrap{
        @include imgwrap(259,243);
        overflow: hidden;
        height: 173px;
        @include min(1200){
            margin-bottom: 19px;
        }
        @include min-max(768,1199.98){
            margin-bottom: 17px;
        }
        @include max(767.9){
            margin-bottom: 15px;
        }
        @include max(374.98) {
            height: 237px;
        }
        img {
            transition: 0.34s ease-in-out transform;
        }
        &:global(.no-contents) {
            background: $blank-folder-outer;
        }
        &:global(.no-contents figure) {
            max-width: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:global(.no-contents img) {
            max-width: 75px;
            width: 100%;
            height: auto;
        }
        &:global(.img-loading) {
            figure {
                @extend .shimmer-styles;
                + div {
                    display: none;
                }
            }
            img {
                opacity: 0;
                &:first-child {
                    transform: scale(1.2, 1.4);
                }
            }
            + div {
                h4 {
                    max-width: 70%;
                    height: 18px;
                    margin-bottom: 16px;
                    @extend .shimmer-styles;
                }
                p {
                    height: 12px;
                    margin-bottom: 8px;
                    @extend .shimmer-styles;
                }
            }
        }
        &:global(.img-loaded) {
            img {
                opacity: 1;
                &:first-child {
                    transform: scale(1, 1.02);
                }
            }
        }
    }
    &_content{
        color: $text-color;
    }
    &_name{
        font-weight: 700;
        color: $text-color;
        @include min(1200){
            margin-bottom: 14px;
        }
        @include min-max(768,1199.98){
            margin-bottom: 10px;
        }
        @include max(767.9){
            margin-bottom: 6px;
        }
    }   
    &_dis{
        line-height: 22px;
        margin-bottom: 0px;
        @include line-clamp(2);
        @include max(575.98) {
            line-height: 20px;
        }
    }
    
    &_hover_list{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #343434;
        color: white;
        padding: 20px;
        display: flex;
        transform: translateY(-100%);
        opacity: 0.1;
        transition: transform 0.3s ease-in-out, opacity 0.1s ease-in-out,  filter 2s ease-in-out;
        will-change: transform, opacity, filter;
        filter: blur(2);
        ul{
            list-style: none;
            padding-left: 0px;
            margin: auto;
            width: 100%;
            li{
                margin: 2px 0;
                padding: 4px 15px;
                font-size: size(20);
                border-radius: 6px;
                transition: background-color 0.3s ease-in;
                cursor: pointer;
                
                &:hover{
                    background-color: #464444;
                }
            }
            :global(.list-group-item){
                background-color: transparent;
                border: none;
                color: white;
            }
        }
    }
    .album_item_hover_list{
        z-index: 3;
    }
    &:hover{
        .album_item_hover_list{
            transform: translate(0%);
            opacity: 1;
            filter: blur(0);
        }
    }
}
.album_item_main_img{
    max-width: calc(100% - 30px);
    max-height: calc(100% - 30px);
    margin: 15px;
    object-fit: cover;
    z-index: 2;
    position: relative;
}
.album_item_photo_frame{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.album_item_options_btn{
    min-width: 20px;
    padding: 0;
    color: $text-color;
    background-color: transparent!important;
    border: none!important;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    &:after{
        display: none;
    }
    svg{
        width: 10px;
        path {
            fill: $text-color-alt;
        }
    }
    + :global(.dropdown-menu) {
        background-color: $white;
        border-radius: 10px;
        padding: 15px 13px;
        position: relative;
        &::before {
          content: "";
          background: $white;
          position: absolute;
          left: 15px;
          width: 14px;
          height: 14px;
        }
        &[x-placement="bottom-start"] {
          transform: translate(-8px, 30px) !important;
          &::before {
            top: 0;
            transform: translateY(-50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 0 0 5px;
            border-bottom: 1px solid $border-color;
            border-left: 1px solid $border-color;
          }
        }
        &[x-placement="bottom-end"] {
          transform: translate(5px, 35px) !important;
          &::before {
            top: 0;
            left: inherit;
            right: 15px;
            transform: translateY(-50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 0 0 5px;
            border-bottom: 1px solid $border-color;
            border-left: 1px solid $border-color;
          }
        }
        &[x-placement="top-start"] {
          transform: translate(0px, -30px) !important;
          &::before {
            bottom: 0;
            transform: translateY(50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 5px 0 0;
            border-top: 1px solid $border-color;
            border-right: 1px solid $border-color;
          }
        }
        &[x-placement="top-end"] {
          transform: translate(0px, -30px) !important;
          &::before {
            bottom: 0;
            right: 15px;
            left: inherit;
            transform: translateY(50%) rotate(45deg);
            border: 1px solid transparent;
            border-radius: 0 5px 0 0;
            border-bottom: 1px solid $border-color;
            border-right: 1px solid $border-color;
          }
        }
        a {
          font-size: size(17);
          font-weight: $font-regular;
          padding: 6px 10px;
          border-radius: 6px;
          overflow: hidden;
          &:hover,
          &:focus {
            color: $text-alt;
            background-color: $dropdown-bg-hover;
          }
        }
      }
}
.ml_auto{
    margin-left: auto;
}

textarea {
    resize: none;
 }
.edit_my_album{
    &,h4{
        color: $text-color;
    }
    :global(.btn-close){
        background-size: 14px;
        @include max(767.98){
            display: none;
        }
    }
    :global(.modal-dialog){
        max-width: 1131px;
    }
    &_leftside{
        background-color: #F6F6F6;
        min-height: max(calc(100vh - 506px), 283px);
    }
    &_rightside{
        padding: 35px;
        :global(.form-control){
            padding: 12px 15px;
            border-color: $input-border;
        }
    }
    @include max(767.98){
        overflow: auto;
        height: 100vh;
        :gloabl(.modal-dialog){
            height: 100%;
        }
        :global(.modal-dialog){
            margin-top: 0px;
            margin-bottom: 0px;
        }
        :global(.modal-content){
            border-radius: 0px!important;
            min-height: 100vh;
        }
    }
    &_mob_header{
        display: flex;
        padding: 6px 40px 6px 10px;
        align-items: center;
        justify-content: center;
    }
    &_close{
        width: 30px;
        height: 30px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            width: 16px;
        }
    }
    &_title_wrap{
        text-align: center;
        width: 100%;
    }
    &_title{
        font-size: size(18);
        margin-bottom: 0px;
    }
    @include max(767.98){
        :global(.modal-body){
            >div{
                margin-bottom: auto;
            }
        }
    }
}
.select_a_frame_col{
    height: 110px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    margin-bottom: 35px;
    &::-webkit-scrollbar {
        width: 4px;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background: rgba(#E0E2E8,50%);
    }
    &::-webkit-scrollbar-thumb {
        background: #252533;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
.select_a_frame_row{
    display: flex;
    flex-wrap: wrap;
    margin-left: -14px;
    margin-right: -14px;
}
.edit_album_submit_btn{
    padding: 12px 15px;
}

.preSelectAlbum{
    // opacity: 0.6;
    .selectionItem {
        box-shadow: 0 0 100vmin 100vmin #00000062;
    }
}

.selectionItem {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    z-index: 10;
    bottom: 25px;
    right: 25px;
    border: 2px solid #bfb5b5;
    // @include max(1349.98) {
    //     top: 122px;
    //     right: 25px;
    // }
    // @include max(1199.98) {
    //     top: 122px;
    //     right: 26px;
    // }
    // @include max(767.98) {
    //     top: 123px;
    //     right: 24px;
    // }
    // @include max(575.98) {
    //     top: 125px;
    // }
    // @include max(374.98) {
    //     top: 190px;
    // }
}

.gallery_select_tick {
    position: absolute;
    z-index: 5;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #1d5eff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    width: 25px;
    height: 25px;
    bottom: 25px;
    right: 25px;
    // @include max(1349.98) {
    //     top: 122px;
    //     right: 25px;
    // }
    // @include max(1199.98) {
    //     top: 122px;
    //     right: 26px;
    // }
    // @include max(767.98) {
    //     top: 123px;
    //     right: 24px;
    // }
    // @include max(575.98) {
    //     top: 125px;
    // }
    // @include max(374.98) {
    //     top: 190px;
    // }
}


@keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    
    100% {
      background-position: 468px 0; 
    }
  }
  
  .placeholder_image_active{
    background-color: $gallery-img-bg;
  }

  @keyframes albumLoadAnim {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
  }