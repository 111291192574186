@import '../../../styles/uitilities.scss';

.fitToframeSection{
    max-width: 350px;
    margin: 0 auto;
    margin-top: 70px;
    @include max(991.98) {
        margin-top: 0;
    }
    @include max(575.98) {
        max-width: 194px;
    }
    .frameFit{  
        border-width: 41px;
        border-radius: 12px;
        border-style: inset;
        display: block;
        margin: 0 auto;
        display: grid;
        place-items: center;
        transition: all 0.4s;
        border-image-slice: 13%;
        border-image-repeat: round;
        overflow: hidden;
        width: 300px;
        height: 300px;
        @include max(991.98) {
            border-width: 15px;
            width: 100%;
            height: inherit;
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
    .sliderSection{
        width: 150px;
        margin: 0 auto;
        margin-top: 20px;
        .sliderHeader {
            color: black;
            text-align: center
        }
    }
    :global(.ant-slider :is(.ant-slider-track, .ant-slider-handle)) {
        background-color: $btn-secondary;
    }
    :global(.ant-slider .ant-slider-handle) {
        border: 2px solid $btn-secondary;
    }
    :global(.ant-slider:hover .ant-slider-handle) {
        border: 2px solid $btn-secondary;
    }
 

    :global(.ant-slider .ant-slider-rail){
            background-color: #302424b8;
         
    }
}



