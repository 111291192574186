@import "../../../styles/uitilities.scss";


:global(.post-date-wrap){
    font-size: size(11);
    line-height: 120%;
    :global(.post-date-label){
        width: 100%;
        display: block;
    }
}