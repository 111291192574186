@import "../../styles/uitilities.scss";

.mobile_input {
  height: 50px;
  display: flex;
  flex-direction: row-reverse;
  .select_wrap {
    position: relative;
    flex: 0 0 60px;
    &::after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.871' height='5.686' viewBox='0 0 9.871 5.686'%3E%3Cpath id='Path_76283' data-name='Path 76283' d='M46.27,25.325,50.145,29.2l3.875-3.875' transform='translate(-45.209 -24.264)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E%0A");
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-58%);
      pointer-events: none;
    }
  }
  select {
    padding-left: 10px;
    border: 1px solid $input-border;
    border-radius: 10px 0 0 10px;
    border-right: 1px solid $input-border-alt;
    background: $input-background;
    width: 100%;
    height: 100%;
    color: $text-color-alt;
    appearance: none;
  }
  input {
    margin-left: -1px;
    &:focus {
      + .select_wrap {
        button {
          border-color: $border-color-alt;
        }
      }
    }
  }
  :global(.form-control) {
    border-radius: 0 10px 10px 0;
    flex: 1;
  }

  

}


// input:-webkit-autofill,
// input:-webkit-autofill:hover, 
// input:-webkit-autofill:focus, 
// input:-webkit-autofill:active{
//     box-shadow: inset 0 0 0 30px $input-background !important;
//     background-color: $input-background;
//     -webkit-text-fill-color: $text-color !important;
//     -webkit-text-fill-color: $text-color !important;
//     transition: none!important;
// }



:global(body.dark){
  .mobile_input {
    .select_wrap {
      &::after {
        filter: invert(0);
      }
    }
  }
}

:global(.error) {
  select {
    border: 1px solid $error-message;
  }
}

.select_wrap {
  &::after {
    filter: invert(1);
  }
}
.prelogin_header_nav_language {
  :global(.dropdown-toggle) {
    background-color: $input-background;
    padding: 0;
    height: 50px;
    border-radius: 6px 0 0 6px;
    border: 1px solid $input-border;
    border-right-color: rgba(#D3D5DB,50%);
    color: $text-color;
    min-width: inherit;
    width: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: size(16);
    font-weight: $font-regular;
    padding-right: 12px;
    &::after {
      display: none;
    }
    &:active,
    &:focus {
      background-color: $input-background;
      border-color: #dedede;
      // color: $font-regular;
      color: $text-color-alt;
    }
  }
  :global(.dropdown-menu[x-placement="bottom-start"]::before) {
    right: inherit;
    left: 25px;
    color: $font-regular;
  }
}

.btn_error {
  :global(.dropdown-toggle) {
    border-color: #ff6261 !important;
  }
}

